<template>
  <div id="PessoaCadastro">

    <!-------- FORM CADASTRO ----------------------------->
    <v-form
      v-model="valid"
      ref="form"
      class="mx-1 mt-3"
      lazy-validation>
      <v-row>
        <v-col>
          <v-text-field
            v-model="store_Pessoa.pessoa_conjuge_edicao.pessoa_nome"
            class="input-nome mb-0 obrigatorio"
            loader-height="1"
            background-color="#FFF"
            label="Nome"
            placeholder="Nome..."
            light
            filled
            outlined
            required
            dense
            :rules="nameRules"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="col-terceira-pf">
        <v-col
          :cols="isMobileProp ? 12 : 4"
          class="pt-0 px-2">
          <v-text-field
          v-model="store_Pessoa.pessoa_conjuge_edicao.pessoa_cpf"
          v-mask="MASK_CPFCNPJ"
          class="mt-n2 mb-0 obrigatorio"
          loader-height="1"
          background-color="#FFF"
          label="CPF"
          placeholder="CPF"
          light
          filled
          outlined
          required
          dense
          max="14"
          :rules="cpfRules"
          ></v-text-field>
        </v-col>

        <v-col 
          :cols="isMobileProp ? 12 : 8"
          :sm="isMobileProp ? 12 : 4"
          class=" pt-0 mt-n3 px-2">
          <DatePicker
            v-model="store_Pessoa.pessoa_conjuge_edicao.pessoa_nasc_dt"
            label="Data do Nascimento"
            placeholder="dia/mês/ano"
            class="mr-0 mt-n2"
            outlined
            backgroundColor="#FFF"
            tabindex="0"
            dense
            :rules="dataRules"
            format="DD/MM/YYYY"
          ></DatePicker>

        </v-col>
      </v-row>
      <v-row class="col-quarta-pf mt-n6" >
        <v-col cols="4" class="d-none d-sm-flex"></v-col>
      </v-row>

      <v-row class="mt-n2 mb-1">
        <v-col 
          :cols="isMobileProp ? 12 : 12"
          :sm="isMobileProp ? 12 : 4"
          :md="isMobileProp ? 12 : 2"
          :lg="isMobileProp ? 12 : 3"
          class="py-0 px-2">
          <v-select 
          v-model="store_Pessoa.pessoa_conjuge_edicao.pessoa_sexo"
          class="mt-n2 obrigatorio"
          label="Sexo"
          filled
          outlined
          dense
          background-color="map-deep-merge white"
          :items="arraySexo"
          item-text="name"
          :rules="sexoRules"
          ></v-select>
        </v-col>
      </v-row>

      <v-row class="telefones mt-n3 mb-3 pt-0 px-0">
        <v-col 
          :cols="isMobileProp ? 12 : 12"
          :sm="isMobileProp ? 12 : 3"
          class="py-0 px-2">
          <v-text-field
          v-model="store_Pessoa.pessoa_conjuge_edicao.fone_celular1"
          v-mask="MASK_TELEFONE"
          class="mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Celular"
          placeholder="(xx) xxxxx-xxxx"
          filled
          outlined
          dense
          :rules="celRules"
          ></v-text-field>
        </v-col>  
      </v-row>

      <v-row class="mt-n3 mb-n4 px-0">
        <v-col 
          :cols="isMobileProp ? 12 : 12"
          :sm="isMobileProp ? 12 : 3"
          class="py-0 px-2">
          <v-text-field
          v-model="store_Pessoa.pessoa_conjuge_edicao.pessoa_profissao"
          class="mt-n2 mb-0"
          light
          loader-height="1"
          background-color="#FFF"
          label="Profissão"
          placeholder="Profissão"
          dense
          filled
          outlined
          :rules="[rules.max(60, store_Pessoa.pessoa_conjuge_edicao.pessoa_profissao)]"
          ></v-text-field>
        </v-col>

        <v-col 
          :cols="isMobileProp ? 12 : 12"
          :sm="isMobileProp ? 12 : 6"
          class="py-0 px-2">
          <v-text-field
          v-model="store_Pessoa.pessoa_conjuge_edicao.pessoa_email"
          class="mt-n2 mb-0"
          light
          loader-height="1"
          hint="Por exemplo: meunome@meuservidor.com.br"
          background-color="#FFF"
          label="Email"
          placeholder="Digite o seu email"
          filled
          outlined
          :rules="emailRules"
          dense
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  <!-- FIM FORM CADASTRO -->
    <v-footer 
      v-if="store_Pessoa.acao != 'C'"
      color="transparent"
      elevation="0"
      class="footer justify-center mt-3 pt-3"
    >
      <v-btn
        @click="cancelar()"
        id="btn_cancelar"
        ref="btn_cancelar"
        class="mr-4 caption font-weight-medium"
        color="primary"
        text
      >
        Cancelar
      </v-btn>

      <v-btn
      :disabled="!valid"
      :loading="loading"
      @click="validate()"
      class="btn caption font-weight-medium white--text"
      color="primary accent-4"
      >
        Salvar
      </v-btn>
    </v-footer>

    <!-- Arquivo/Webcam Dialog ------------------------------------------ -->
    <v-dialog
      v-if="dialog_crop"
      :value="true"
      persistent
      style="width: 330"
      :max-width="330"
      :retain-focus="false"
    >
      <v-card>
        <v-card-title>Foto</v-card-title>
        <v-card-text class="pt-7">
          <VueCropImage
            :img="store_Pessoa.pessoa_conjuge_edicao.pessoa_foto"
            :imgsync.sync="store_Pessoa.pessoa_conjuge_edicao.pessoa_foto"
            :dialog_crop.sync="dialog_crop"
            ref="VueCropImage"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import store_Pessoa from "./store_Pessoa";
import store_site from "../../store/store_site";
import {
  formatDate,
  maskCpfCnpj,
  CpfCnpjIsValid,
  emailIsValid,
  telefoneIsValid,
} from "../../services/funcoes";
import { COR_PRINCIPAL, COR_SUBTITULO, COR_SECUNDARIA, MASK_CPFCNPJ, MASK_TELEFONE, arraySexo, arrayEstadoCivil, arrayPaises, arrayRegimeBens } from "../../services/constantes";
import { nomeIniciais } from "../../services/funcoes"
import DatePicker from "../ComponentesGlobais/Date-Picker/date-picker.vue";
import VueCropImage from "../ComponentesGlobais/CropImage/VueCropImage.vue";

export default {
  name: "PessoaCadastroConjugeMobile",

  props: {
    isMobileProp: {
      type    : Boolean,
      default : false
    }
  },

  components: {
    VueCropImage,
    DatePicker,
  },

  data() {
    return {
      store_Pessoa  : store_Pessoa,
      store_site    : store_site,

      maskCpfCnpj   : maskCpfCnpj,
      formatDate    : formatDate,
      nomeIniciais  : nomeIniciais,

      img                     : null,
      imgsync                 : null,
      dialog_crop             : false,
      dialog_webcam           : false,
      dialog_excluir_tipo     : false,
      dialog_excluir          : false,
      dialog_excluir_registro : false,
      loading_excluir         : false,
      dialogConjuge           : false,

      MASK_CPFCNPJ      : MASK_CPFCNPJ,
      MASK_TELEFONE     : MASK_TELEFONE,
      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO,  
      COR_SECUNDARIA    : COR_SECUNDARIA,
      arraySexo         : arraySexo,
      arrayEstadoCivil  : arrayEstadoCivil,
      arrayPaises       : arrayPaises,
      arrayRegimeBens   : arrayRegimeBens,

      isMobile          : false,

      pessoa_edicaoAux            : null,
      dados_modal_endereco        : {},
      dados_modal_contato         : {},
      dados_modal_bancario        : {cod_pessoa: null, cod_banco: null, acao: null},
      dados_modal_bancario_bancos : [],
      
    /* Campos */
      valid                 : true,
      loading               : false,

      conjuge               : { cod_pessoa       : null,
                                pessoa_nome      : null,
                                pessoa_cpf       : null,
                                pessoa_nasc_dt   : null,
                                pessoa_profissao : null
                              },

    /* Título Tabela Endereços */
      headers_enderecos: [
        { text: "Logradouro", value: "logradouro", class: 'title-text'},
        { text: "N", value: "N", class: 'title-text', },
        { text: "Compl", value: "Compl", class: 'title-text', },
        { text: "Bairro", value: "Bairro", class: 'title-text', },
        { text: "Cep", value: "Cep", class: 'title-text', },
        { text: "Cidade", value: "Cidade", class: 'title-text', },
        { text: ""},
      ],

    /* Títulos Tabela Contatos */
      headers_contatos: [
        { text: "Nome contato", value: "Nome contato", class: 'title-text' },
        { text: "Telefone 01", value: "Telefone 01", class: 'title-text' },
        { text: "Telefone 02", value: "Telefone 02", class: 'title-text' },
        { text: "Telefone 03", value: "Telefone 03", class: 'title-text' },
        { text: ""},        
      ],

    /* Títulos Tabela Dados Bancários */
      headers_dados_bancarios: [
        { text: "Banco", value: "Banco", class: 'title-text' },
        { text: "Agência", value: "Agência", class: 'title-text' },
        { text: "Conta", value: "Conta", class: 'title-text' },
        { text: "Titular", value: "Titular", class: 'title-text' },
        { text: "CPF/CNPJ", value: "CPF/CNPJ", class: 'title-text' },
        { text: ""},
      ],

    /* Tabela Dados Bancarios */
      dados_bancarios: [
        {banco: "Bradesco", agencia: "18009-23", conta: "00000000", titular: "Fernando Gracia", cpf_cnpj_titular: "000.000.000-00"},
      ],

    /* Títulos Tabela Sócios*/
      headers_socios: [
        { text: "Sócio", value: "Sócio", class: 'title-text' },
        { text: "Porcentagem %", value: "Porcentagem %", class: 'title-text' },
      ],

    /* Tabela Sócios */
        socios: [
        {socio: "João Feliciano", porcentagem: "20%"},
      ],

    /* Formulário Reprovação */
      //snack: false,
      //snackColor: "",
      //snackText: "",
      //form: false,
      //max25chars: (v) => v.length <= 25 || "Input too long!",

      rules: {
        min(min, v) { 
          return (v || '').length >= min || `Mínimo de ${min} caracteres`;
        },
        max(max, v) { 
          return (v || '').length <= max || `Máximo de ${max} caracteres`;
        },
        data(v) { 
          return (v || '').length >= 10 || `Mínimo de 10 caracteres`;
        },
      },
    
      nameRules: [
        (value) => !!value || "O nome é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O nome deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O nome deve ter menos do que 60 caracteres",
      ],
      razaoRules: [
        (value) => !!value || "A Razão Social é obrigatória",
        (value) =>
          (value && value.length >= 3) ||
          "A Razão Social deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "A Razão Social deve ter menos do que 60 caracteres",
      ],
      sexoRules: [
        (value) => !!value || "O Sexo é obrigatório",
      ],
      emailRules: [
        (value) => 
          ((!value || value.length <= 0) || (value && emailIsValid(value))) || "O e-mail não é válido",
        (value) =>
          (!value || value.length <= 100) || "O Email deve ter menos do que 100 caracteres",
      ],
      dataRules: [
        (value) => 
          ((!value || value.length <= 0) || (value && moment(value, 'DD/MM/YYYY', true).isValid())) || "A Data não é válida",
        (value) =>
          (!value || value.length <= 10) || "A Data deve ter menos do que 10 caracteres",
      ],
      rgRules: [
        (value) =>
          (!value || value.length <= 15) || "O RG deve ter menos do que 15 caracteres",
      ],
      orgaoRules: [
        (value) =>
          (!value || value.length <= 10) || "O Orgão Expedidor deve ter menos do que 10 caracteres",
      ],
      foneRules: [
        (value) => 
          ((!value || value.length <= 0) || (value && telefoneIsValid(value))) || "O telefone não é válido",
      ],
      celRules: [
        (value) => 
          ((!value || value.length <= 0) || (value && telefoneIsValid(value))) || "O celular não é válido",
      ],
      estadocivilRules: [
        (value) => !!value || "O Estado Civil é obrigatório",
      ],
      cpfRules: [
        (value) => !!value || "O CPF é obrigatório",
        (value) => (value && CpfCnpjIsValid(value)) || "O CPF não é válido",
        (value) =>
          (value && value.length <= 14) ||
          "O CPF deve ter 14 caracteres",
      ],
      cnpjRules: [
        (value) => !!value || "O CNPJ é obrigatório",
        (value) => (value && CpfCnpjIsValid(value)) || "O CNPJ não é válido",
        (value) =>
          (value && value.length <= 18) ||
          "O CNPJ deve ter 18 caracteres",
      ],
    };
  },

  created() {
    { this.isMobile = true; }
    { this.isMobile = true; }
  },
  async mounted() {
    // var lo_params = { cod_pessoa: this.$route.params.cod_pessoa };
    //     if (lo_params.cod_pessoa) {
    //       this.store_Pessoa.acao = "C";
    //       const lo_Res = await this.store_Pessoa.PessoaGet(lo_params);
    //       if ( lo_Res.rows.length > 0) {
    //         this.store_Pessoa.pessoa_conjuge_edicao = { ...lo_Res.rows[0] };
    //       }
    //     }
  },
  destroyed() {
    this.pessoa_edicaoAux = null;
  },

  methods: {
    cancelar() {
      this.store_Pessoa.dialog_cadastro_conjuge = false;
      // this.store_Pessoa.acao = 'C';
      this.store_Pessoa.pessoa_conjuge_edicao = {};

    },
    async validate() {
      // console.log('entrei aqui', store_Pessoa.pessoa_conjuge_edicao.pessoa_nome);
      // return
      const lb_valido = this.$refs.form.validate();
      if (lb_valido) {
        this.loading = true;
        
        var lo_JSON = { ...this.store_Pessoa.pessoa_conjuge_edicao };

        var ls_Res = await this.store_Pessoa.PessoaPost(lo_JSON);
        // console.log("ls_Res",ls_Res);
        // return

        // this.$emit('busca_Pessoa', ls_Res.result.data.pessoa_cpf);

        if ((ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') ||
            (ls_Res.status && ls_Res.status == 200)) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = ls_Res.result.result.trim();
          this.store_site.alert           = true;
        } else {
          this.store_site.alert_cor       = "#A00000";
          this.store_site.alert_timeout   = 20000;
          this.store_site.alert_msg       = ls_Res.errors.trim();
          this.store_site.alert           = true;
        }
        this.loading = false;
        // this.store_Pessoa.acao = 'C';
        this.store_Pessoa.dialog_cadastro_conjuge = false;
        this.store_Pessoa.preenche_search_pessoa_cadastrada = store_Pessoa.pessoa_conjuge_edicao.pessoa_nome
        this.store_Pessoa.pessoa_conjuge_edicao = {};

          this.store_Pessoa.dialogConjuge = false
        this.$nextTick(() => {
          this.store_Pessoa.dialogConjuge = true
        })
      }
    },
  },
};
</script>

<style scoped>
#PessoaCadastro {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media(max-width: 599px) {
  #PessoaCadastro {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#PessoaCadastro::-webkit-scrollbar {
  width: 5px;
}

#PessoaCadastro::-webkit-scrollbar-button {
  padding: 1px;
}

#PessoaCadastro::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#PessoaCadastro::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #PessoaCadastro::-webkit-scrollbar {
    width: initial;
  }

  #PessoaCadastro::-webkit-scrollbar-button {
    padding: initial;
  }

  #PessoaCadastro::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #PessoaCadastro::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.nav-link {
  text-decoration: none;
}

.btn {
  width: 100px;
}

.btn-icon {
  font-size: 17px;
}

.btn-icon-plus {
  font-size: 20px;
}

.icon-action {
  font-size: 20px!important;
  color: #9E9E9E;
}

.my-custom-dialog {
  align-self: center;
  position: absolute;
  bottom: 0;
  right: 0;
  color: red;
}

.flex-column {
  flex-direction: column!important;
}

.input-rg {
  margin-top: -18px;
}

.input-inscricao {
  margin-top: -22px;
}

@media(max-width: 599px) {
  .input-rg {
    margin-top: -8px;
  }

  .input-inscricao {
    margin-top: -10px;
  }

  .col-terceira-pf div:nth-child(2), .col-terceira-pj div:nth-child(2) {
    margin-top: -16px;
  }

  .col-terceira-pj div:nth-child(1) {
    margin-bottom: 4px;
  }

  .col-terceira-pf div:nth-child(3), .col-terceira-pj div:nth-child(3) {
    margin-top: -12px;
  }

  .col-terceira-pj div:nth-child(3) {
    margin-bottom: -4px;
  }

  .col-quarta-pf div:nth-child(2), .col-quarta-pj div:nth-child(2) {
    margin-top: 13px;
    margin-bottom: -10px;
  }

  .col-quinta-pj div:nth-child(3) {
    margin-top: -12px;
  }

  .telefones div:not(.telefones div:first-child) {
    margin-top: -8px;
  }

  .input-nome {
    margin-bottom: -8px!important;
  }

  .input-razao {
    margin-bottom: -12px!important;
  }

  .input-date {
    margin-top: -12px;
  }

  .input-conjuge, .input-date-nasc, .input-date-cas {
    margin-top: -8px;
  }
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  padding: 2px;
  background: var(--COR_SECUNDARIA)!important;
  border-top-width: 1px!important;
  border-top-style: solid!important;
  border-top-color: var(--COR_SOMBRA)!important;
}

/* .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 10px;
  width: 200px !important;
} */

</style>
                
