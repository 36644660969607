<template>
  <v-container id="PessoaConsultaMobile" class="pa-0 ma-0" v-bind:style="{ 'background-color':COR_BACKGROUND_TEXT }">

    <!-- Botões de ação cabeçalho -->
    <v-toolbar-title 
      v-bind:style="{ 'background-color':COR_PRINCIPAL }"
      class="d-flex align-center" tabindex="-1"
      dark
      elevation="0">
      <v-text-field
        id="txtBuscar"
        ref="txtBuscar"
        v-on:keyup="filtro = $event.target.value"
        prepend-inner-icon="mdi-magnify"
        autofocus
        dense
        v-model="search"
        cache-items
        class="ml-3 search-input"
        v-bind:class="{'search-input-longo': search_input_focus}"
        flat
        dark
        label=""
        solo-inverted
        clearable
        @focus="onFocus($event)"
        @blur="onBlur($event)"
        tabindex="-1">
      </v-text-field>

      <v-btn 
        @click="dialog_selecao = true"
        class="mr-3 btn-new text-none"
        :color="COR_PRINCIPAL"
        elevation="0">
        <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
        <span>Novo</span>
      </v-btn>
    </v-toolbar-title>

    <v-container class="pa-0 mb-16">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="dados"
        single-select
        show-select
        fixed-header
        top="0px"
        hide-default-header
        hide-default-footer
        loading-text="Carregando...  aguarde..."
        no-data-text="Nenhum Registro Encontrado"
        no-results-text="Nenhum Registro Encontrado">
        <template #item="{ item }">
            <tr :search="search">
              <td class="td-height" >
                <v-checkbox
                  v-on:change="seleciona($event, item)"
                  :value="item.cod_pessoa == store_ModalNegociacao.pessoa_selecionado.cod_pessoa"
                  :key="item.cod_pessoa"
                  hide-details
                  class="mr-n6"
                  style="margin-top: -50%;" />
              </td>
              <td class="hide-sm py-2" style="width: 99%">
                <span style="font-size: 15px; display:block; color:#385986 !important;" class="font-weight-bold">{{ item.pessoa_nome }}</span>
                <span style="font-size: 12px; display:block; color:#808080 !important;" v-if="item.pessoa_cpf">CPF: {{ maskCpfCnpj(item.pessoa_cpf) }}</span>
                <span style="font-size: 12px; display:block; color:#808080 !important;" v-if="item.pessoa_cnpj">CNPJ: {{ maskCpfCnpj(item.pessoa_cnpj) }}</span>
                <span style="font-size: 12px; display:block; color:#808080 !important;" >{{ item.fone_celular1 }}</span>
                <span style="font-size: 12px; display:block; color:#808080 !important;" >{{ item.pessoa_email }}</span>
                </td>
              <td style="width:1%">
                <v-btn
                  @click="pessoaCadastroEdicao(item)"
                  icon
                  dense>
                  <v-icon
                    color="grey" 
                    class="btn-icon">
                    mdi-pencil-outline
                  </v-icon>
                </v-btn>
              </td>
                  
            </tr>
          </template>
        </v-data-table>
      </v-container>

    <!-- NOVO MODAL USANDO VUETIFY -->
    <v-container
      v-if="store_Pessoa.dialog_cadastro_mobile"
      class="px-0">
      <v-dialog v-model="store_Pessoa.dialog_cadastro_mobile"
        max-width="440"
        :retain-focus="false"
        persistent>
        <v-card class="px-0" v-bind:style="{ 'background-color':COR_BACKGROUND }">
          <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
            class="justify-space-between py-2 px-3 header">
            <span class="text-white title-page body-1">Cadastro de Pessoa</span>
              <v-btn
                icon
                dark
                color="#F2F6F7"
                @click="store_Pessoa.dialog_cadastro_mobile = false; store_Pessoa.pessoa_leads_edicao = false; store_Pessoa.pessoa_edicao = {}; dados_modal_endereco = {}">
              <v-icon large class="title">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-container v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física' ">
            <v-card-text  class="pt-2 px-2">
                <PessoaCadastroMobile
                  ref="PessoaCadastroMobile"
                  :isMobileProp="true"
                  class="px-0"/>
            </v-card-text>
          </v-container>
          <v-container v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Jurídica' ">
            <v-card-text class="pt-2 px-2">
                <PessoaCadastroJuridicaMobile
                  ref="PessoaCadastroJuridicaMobile"
                  :isMobileProp="true"
                  class="px-0"/>
            </v-card-text>
          </v-container>


        </v-card>
      </v-dialog>
    </v-container>

    <!-- Dialogo de Seleção de tipo de pessoa -->

    <!-- Cabeçalho seleção de pessoa -->
    <v-container
      v-if="dialog_selecao">
      <v-dialog
        v-model="dialog_selecao"
        scrollable
        max-width="350px"
        max-height="500px">
        <v-card>
          <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }">
            <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
              <div class="pa-0" style="width:100%">
                <p style="color:white; white-space: pre-line; font-size:16px !important;">
                  Seleção de pessoa
                </p>                      
              </div>
              <div class="pa-0 text-center" style="width:40px;">
                <v-btn icon dark v-on:click="dialog_selecao = false" class="mt-n2">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-card-title>
      <!--FIm Cabeçalho selação de pessoa -->
      
      <!--Corpo selação de pessoa -->

      <v-card-text class="pa-0" style="height: 70px;">
          <v-col 
          cols="12"
          class="mt-n1 py-0 ">          
          <v-radio-group 
              mandatory
              row
              class="px-2 small-radio"
              v-model="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica">
            <v-radio
              key="1"
              label="Pessoa Física"
              value="Física"
              checked="checked">
            </v-radio>
            <v-spacer></v-spacer>
            <v-radio
              key="2"
              label="Pessoa Jurídica"
              value="Jurídica"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-card-text>

      <!-- Fim Corpo selação de pessoa -->

      <!-- Rodapé selação de pessoa -->
          <v-card-actions class="d-flex justify-center pa-3" v-bind:style="{ 'background-color':COR_SECUNDARIA }">
            <v-btn
              @click="dialog_selecao = false"
              class="mr-4 btn text-none"
              color="primary"
              text>
              Cancelar
            </v-btn>
            <v-btn
              class="btn text-none"
              style="width: 5.5rem"
              color="primary"
              @click="Novo()">
              Selecionar
            </v-btn>
          </v-card-actions>
      <!-- Fim Rodapé selação de pessoa -->
        </v-card>
      </v-dialog>
    </v-container>
    <!-- Fim Dialogo de Seleção de tipo de pessoa -->


    
    <!-- NOVO MODAL USANDO VUETIFY -->
  </v-container>
</template>

<script>
import store_Pessoa from "./store_Pessoa";
import store_ModalNegociacao from "../ModalNegociacao/store_ModalNegociacao";
import store_Login from "../Login/store_Login"
import { maskCpfCnpj } from "../../services/funcoes";
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_BACKGROUND, COR_BACKGROUND_TEXT } from "../../services/constantes"
import PessoaCadastroMobile from "./PessoaCadastroMobile.vue"
import PessoaCadastroJuridicaMobile from "./PessoaCadastroJuridicaMobile.vue"

export default {
  name: "PessoaConsultaMobile",

  components: {
    PessoaCadastroMobile,
    PessoaCadastroJuridicaMobile,
  },

  props: {
    editar_ :{  type    : Boolean,
                default : false,
    }
  },

  data() {
    return {
      store_Pessoa            : store_Pessoa,
      store_ModalNegociacao   : store_ModalNegociacao,
      store_Login             : store_Login,

      maskCpfCnpj             : maskCpfCnpj,

      COR_PRINCIPAL           : COR_PRINCIPAL,
      COR_BACKGROUND          : COR_BACKGROUND,
      COR_BACKGROUND_TEXT     : COR_BACKGROUND_TEXT,
      COR_SECUNDARIA          : COR_SECUNDARIA,

      lb_timeout              : true,
      loading                 : false,
      search                  : null,
      dialog_selecao          : false,

      selected                : [],
      checked                 : false,
      search_input_focus      : false,

      dados                   : [],

      /* Título Tabela */
      headers: [
        { text: "", },
        { text: "", },
        { text: "Nome",  value: "nome", },
        { text: "Email", value: "email" },
        { text: "Telefone", value: "telefone" },
        { text: "", },
        { text: "", },
      ],
    };
  },

  mounted() {
    this.store_Pessoa.pessoa_selecionado = null
    if(this.store_Pessoa.preenche_search_pessoa_cadastrada != ''){
      this.search = this.store_Pessoa.preenche_search_pessoa_cadastrada
      this.store_Pessoa.preenche_search_pessoa_cadastrada = ''
    }
    this.busca_Pessoa();
  },

  watch: {
    search(val) {
      this.store_Pessoa.pessoa_selecionado = null
      this.busca_Pessoa_timeout(val);
    },
  },

  methods: {
    onBlur() {
        this.search_input_focus = false;
    },

    onFocus() {
        this.search_input_focus = true;
    },

    seleciona(value, item) {
      // console.log("🚀 ~ file: PessoaConsultaMobile.vue:302 ~ seleciona ~ value:", value)
      if (value) {
        this.store_ModalNegociacao.pessoa_selecionado = item;
        this.store_Pessoa.pessoa_selecionado          = item;
        //console.log("this.store_ModalNegociacao.pessoa_selecionado", this.store_ModalNegociacao.pessoa_selecionado.pessoa_nome)
      }
      else {
        this.store_ModalNegociacao.pessoa_selecionado = [];
        this.store_Pessoa.pessoa_selecionado          = null;
      }
    },

    async busca_Pessoa(p_params) {
      // console.log(p_params);
      this.loading = true;
      var lo_params = {};
      if (p_params)
        lo_params = { filtro: p_params };
      this.store_ModalNegociacao.pessoa_selecionado = [];
      const lo_Res = await this.store_Pessoa.PessoaGet(lo_params);
      if (lo_Res && lo_Res.rows.length > 0)
        this.dados = lo_Res.rows;
      else
        this.dados = [];
      this.loading = false;
      this.lb_timeout = false;
    },

    async Editar(p_pessoa) {
      var params = {cod_pessoa : p_pessoa}
      this.store_Pessoa.pessoa_edicao = {};
      this.store_Pessoa.pessoa_edicao = await this.store_Pessoa.PessoaGet(params);
      this.store_Pessoa.dialog_cadastro_mobile = true;
    },

    pessoaCadastroEdicao(item){
      // console.log('item', item);
      this.store_Pessoa.pessoa_edicao = {...item}; 
      this.store_Pessoa.acao = 'E'; 
      this.store_Pessoa.dialog_cadastro_mobile = true;
    },

    Novo() {
      this.store_Pessoa.acao = "I";
      this.store_Pessoa.pessoa_selecionado = {}; 
      this.dialog_selecao = false;
      this.store_Pessoa.dialog_cadastro_mobile = true;
      // console.log('fisica ou juridica');
    },

    busca_Pessoa_timeout(val) {
      if (val) {
        val = val.trim();
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.busca_Pessoa(val);
      }, 450);
    },
  },
};
</script>

<style scoped>
/*-----------------------------------*/
/*------ TABLE ------*/
/*-----------------------------------*/
.main-title {
  background: #0548a3;
}

@media (min-width: 1024px) {
  .container-title {
    max-width: calc(100% - 32px) !important;
  }
}

.text-white {
  font-family: "Montserrat", sans-serif !important;
  font-size: 20px;
  color: rgba(255,255,255,0.8);
}

.accordion-panel {
  box-shadow: none !important;
}

.expansion-panel {
  border-radius: 8px 8px 0px 0px;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

table tr {
  width: 100%;
}

.table-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
}

.table-row:hover {
  background: #f6f6f6 !important;
}

.panel-header {
  height: 60px;
  font-family: "Montserrat", sans-serif !important;
  font-size: 17px;
  background: #f6f6f6 !important;
  color: #4476ba;
}

.wrapper-title,
.v-toolbar,
.v-sheet {
  display: flex !important;
  flex-direction: column !important;
  padding: 0px !important;
}

.v-toolbar__content {
  padding-left: 0px !important;
}

.v-input__control {
  background: #2a63b1 !important;
}

  .search-input {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
    /* min-width: calc(100% - 15px); */
  }

  @media (max-width: 768px) {
    .search-input {
      margin: 0.8rem 10% 1.2rem 0;
    }
    .search-input-longo {
      margin: 0.8rem 10% 1.2rem 0;
      /* min-width: calc(100% - 15px); */

    }
  }

.v-input__control,
.v-input__slot,
.v-select__slot {
  height: 35px !important;
}

.v-label {
  margin-right: 10px !important;
}

.icon-action {
  font-size: 1.3rem !important;
}

@media (max-width: 768px) {
  .btn-icon {
    margin-right: 8px !important;
  }
}

.container-content {
  border-radius: 20px 20px !important;
  background: #fff !important;
}

@media (max-width: 768px) {
  .main-title {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .container-content {
    max-width: 740px !important;
  }
}

@media (max-width: 599px) {
  .container-content {
    padding: 16px !important;
  }
}

.container-table {
  max-width: calc(100% - 32px) !important;
  max-width: 400px !important;
  border-radius: 8px !important;
  background: #fff !important;
}

@media (max-width: 375px) {
  .container-table {
    max-width: 100% !important;
    border-radius: 16px !important;
  }
}

.wrapper-title div {
  padding-left: 0px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1.5rem !important;
}

.status_ativo {
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_pendente {
  font-size: 14px !important;
  background: goldenrod;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.v-toolbar__title {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.wrapper-title {
  margin-bottom: 12px;
}

.row-container {
  /* 
  background: ;display: flex !important;
  justify-content: right !important;
  align-items: center;
  margin: 0px;
  */
}

.col-main {
  padding: 0px !important;
  margin: 0px !important;
  width: 100% !important;
}

.col-avatar {
  padding-left: 16px !important;
}

.nav-link {
  padding: 8px 0px;
}

.v-btn {
  font-size: 12px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.btn-new {
  font-size: 14px;
  color: white;
  width: 100px;
}

.td-height {
  min-height: 60px !important;
}

.header {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0em;
  z-index: 2;
}

</style>