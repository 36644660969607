<template>
  <div id="PessoaCadastro">
    <!-- TÍTULO DA PÁGINA -->
    <v-toolbar-title 
      v-bind:style="{ 'color':COR_SUBTITULO }"
      class="d-flex align-center justify-space-between body-1 font-primary ml-2 header">
      <p class="font-primary font-weight-bold font-weight-medium body-1" v-bind:style="{ 'color':COR_SUBTITULO }">
        Dados Básicos
      </p>
    </v-toolbar-title>

    <!-------- FORM CADASTRO ----------------------------->
    <v-form
      v-model="valid"
      ref="form"
      class="mx-1 mt-n5">
      <v-row>
        <v-col
          :cols="isMobileProp ? 12 : 12"
          :sm="isMobileProp ? 12 : 4">
          <v-list-item-content class="justify-center">
            <div class="d-flex justify-center mt-n8 mb-n2 pt-5">
              <v-avatar
                size="102"
                color="#909090" >
                <v-avatar
                  v-if="store_Pessoa.acao == 'C'"
                  size="100"
                  rounded="false"
                  style="top: 0px!important; left: 0px!important">
                  <span
                    v-if="!store_Pessoa.pessoa_edicao.pessoa_foto"
                    color="#274ABB"
                    class="headline">
                    {{ nomeIniciais(store_Pessoa.pessoa_edicao.pessoa_nome) }}
                  </span>
                  <img
                    v-if="store_Pessoa.pessoa_edicao.pessoa_foto"
                    :src="store_Pessoa.pessoa_edicao.pessoa_foto"
                  />
                </v-avatar>
                <v-avatar
                  v-if="store_Pessoa.acao != 'C'"
                  size="100"
                  class="grey lighten-2 pa-1"
                  style="top: 0px!important; left: 0px!important">
                  <span
                    v-if="!store_Pessoa.pessoa_edicao.pessoa_foto"
                    v-on:click="dialog_crop = true">
                    Clique p/ adicionar avatar
                  </span>
                  <img
                    v-if="store_Pessoa.pessoa_edicao.pessoa_foto"
                    v-on:click="dialog_crop = true"
                    :src="store_Pessoa.pessoa_edicao.pessoa_foto"
                    alt="avatar"
                  />
                </v-avatar>
              </v-avatar>
            </div>
          </v-list-item-content>
        </v-col>
      </v-row>
      
      <v-row class="mt-n2">
        <v-col
          :cols="isMobileProp ? 12 : 8"
          class="px-2" >
          <v-text-field
            class="obrigatorio"
            v-model="store_Pessoa.pessoa_edicao.pessoa_nome"
            :readonly="store_Pessoa.acao == 'C'"
            background-color="#FFF"
            label="Razão Social"
            placeholder="Razão Social"
            light
            autofocus
            filled
            outlined
            required
            dense
            :rules="razaoRules">
          </v-text-field>
        </v-col>
      </v-row>
    
      <v-row class="mt-n6">
        <v-col
        :cols="isMobileProp ? 12 : 12"
        :sm="isMobileProp ? 12 : 4"
        class="px-2">
          <v-text-field
            class="obrigatorio"
            v-model="store_Pessoa.pessoa_edicao.pessoa_cnpj"
            :readonly="store_Pessoa.acao == 'C'"
            v-mask="MASK_CPFCNPJ"
            light
            background-color="#FFF"
            label="CNPJ"
            placeholder="CNPJ"
            filled
            outlined
            :rules="cnpjRules"
            required
            dense
            max="18">
          </v-text-field>
        </v-col>
      </v-row>
    
      <v-row class="mt-n6">
        <v-col
        :cols="isMobileProp ? 12 : 12"
        :sm="isMobileProp ? 12 : 4"
        class="px-2">
        <DatePicker
          v-model="store_Pessoa.pessoa_edicao.pessoa_firma_abertura_dt" 
          label="Data de Abertura"
          placeholder="dia/mês/ano"
          outlined
          backgroundColor="#FFF"
          tabindex="0"
          dense
          :rules="dataRules"
          format="DD/MM/YYYY"
          :readonly="store_Pessoa.acao == 'C'">
        </DatePicker>
      </v-col>
    </v-row>
      
    <v-row class="mt-n6">
      <v-col 
        :cols="isMobileProp ? 12 : 12"
        :sm="isMobileProp ? 12 : 4"
        class="px-2">
        <v-text-field
          v-model="store_Pessoa.pessoa_edicao.pessoa_insc_estadual"
          :readonly="store_Pessoa.acao == 'C'"
          background-color="#FFF"
          label="Inscrição Estadual"
          placeholder="Inscrição Estadual"
          filled
          outlined
          dense
          :rules="inscricaoRules">
        </v-text-field>
      </v-col>
    </v-row>

    <v-row class="mt-n6">
      <v-col 
        :cols="isMobileProp ? 12 : 12"
        :sm="isMobileProp ? 12 : 4"
        class="px-2">
        <v-text-field
          v-model="store_Pessoa.pessoa_edicao.pessoa_insc_municipal"
          :readonly="store_Pessoa.acao == 'C'"
          background-color="#FFF"
          label="Inscrição Municipal"
          placeholder="Inscrição Municipal"
          filled
          outlined
          dense
          :rules="inscricaoRules">
        </v-text-field>
      </v-col>
    </v-row>

    <v-row class="mt-n6">
      <v-col 
        :cols="isMobileProp ? 12 : 12"
        :sm="isMobileProp ? 12 : 4"
        class="px-2">
        <v-text-field
          v-model="store_Pessoa.pessoa_edicao.pessoa_cnae"
          :readonly="store_Pessoa.acao == 'C'"
          light
          loader-height="1"
          background-color="#FFF"
          label="Cnae"
          placeholder="Cnae"
          dense
          filled
          outlined
          :rules="cnaeRules">
        </v-text-field>
      </v-col>
    </v-row>

    <v-row class="mt-n6">
      <v-col 
        :cols="isMobileProp ? 12 : 12"
        :sm="isMobileProp ? 12 : 4"
        class="px-2">
        <v-text-field
          v-model="store_Pessoa.pessoa_edicao.ramo_negocio"
          :readonly="store_Pessoa.acao == 'C'"
          class="mb-5"
          light
          loader-height="1"
          background-color="#FFF"
          label="Ramo Negócio"
          placeholder="Ramo Negócio"
          filled
          outlined
          dense
          :rules="ramoNegocioRules">
        </v-text-field>
      </v-col>
    </v-row>

      <!----------- CONJUGE --------------------->
      <!-- <v-row v-if="(store_Pessoa.pessoa_edicao.pessoa_estadocivil == 'Casado(a)' || store_Pessoa.pessoa_edicao.pessoa_estadocivil == 'União Estável(a)') && store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física' || store_Pessoa.pessoa_edicao.pessoa_estadocivil == 'União Estável' && store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física'" class="mb-12 px-0">
        <fieldset class="mt-n10 mb-6 d-flex flex-column mx-3 pt-4 px-3 pb-1 rounded" style="width: 100%; border: 1px solid #c4c4c4">
          <legend class="ml-1 mb-1 font-weight-medium body-2" v-bind:style="{ 'color':COR_SUBTITULO }"> Cônjuge </legend>
          <v-row class="px-0">
            <v-col cols="12" sm="12" class="py-0 px-0">
              <v-text-field
                v-model="conjuge.pessoa_nome"
                readonly
                class="input-conjuge mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Nome do Cônjuge"
                placeholder="Nome do Cônjuge"
                filled
                outlined
                required
                dense>
                <template v-slot:append-outer>
                  <v-icon
                    v-if="conjuge.cod_pessoa"
                    @click="store_Pessoa.dialogConjugeExcluir = true"
                    :disabled="store_Pessoa.acao == 'C'"
                    class="input-conjuge mb-0"
                    color="primary">
                    mdi-delete
                  </v-icon>
                  <v-icon
                    @click="store_Pessoa.dialogConjuge = true"
                    :disabled="store_Pessoa.acao == 'C'"
                    class="ml-2"
                    color="primary">
                    mdi-magnify-plus
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" class="py-0 px-2">
              <v-text-field
                v-model="conjuge.pessoa_cpf"
                v-mask="MASK_CPFCNPJ"
                class="input-conjuge mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="CPF do Cônjuge"
                placeholder="CPF do Cônjuge"
                filled
                outlined
                readonly
                dense>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" class="py-0 px-2">
              <v-select
                v-model="conjuge.pessoa_nacionalidade"
                label="Nacionalidade"
                class="input-conjuge mb-0"
                filled
                outlined
                readonly
                dense
                background-color="#FFF"
                :items="arrayPaises"
                item-text="nome">
              </v-select>
            </v-col>

            <v-col cols="12" sm="12" class="py-0 px-2">
              <DatePicker
                v-model="conjuge.pessoa_nasc_dt" 
                label="Nascimento do Cônjuge"
                placeholder="dia/mês/ano"
                class="input-conjuge mb-0"
                outlined
                backgroundColor="#FFF"
                tabindex="0"
                dense
                :rules="dataRules"
                format="DD/MM/YYYY"
                readonly>
              </DatePicker>
            </v-col>

          <v-col cols="12" sm="12" class="py-0 px-2">
              <v-text-field
                v-model="conjuge.pessoa_profissao"
                class="input-conjuge mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Profissão do Cônjuge"
                placeholder="Profissão do Cônjuge"
                filled
                outlined
                readonly
                dense>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" class="py-0 px-2">
              <v-select
                v-model="store_Pessoa.pessoa_edicao.cod_regime"
                :readonly="store_Pessoa.acao == 'C'"
                class="input-conjuge mb-0"
                label="Regime de Bens"
                filled
                outlined
                dense
                clearable
                background-color="map-deep-merge white"
                :items="arrayRegimeBens"
                item-text="name"
                item-value="codigo">
              </v-select>
            </v-col>

            <v-col cols="12" sm="12" class="py-0 px-2">
              <DatePicker
                v-model="store_Pessoa.pessoa_edicao.pessoa_casamento_dt" 
                label="Data do Casamento"
                placeholder="dia/mês/ano"
                class="input-conjuge mb-0"
                outlined
                backgroundColor="#FFF"
                tabindex="0"
                dense
                :rules="dataRules"
                format="DD/MM/YYYY"
                :readonly="store_Pessoa.acao == 'C'">
              </DatePicker>
            </v-col>
          </v-row>
        </fieldset>
      </v-row> -->
      <!----------- FIM CONJUGE --------------------->

      <!-- TABELA SOCIOS -------------------------------------------------------->
      <v-row class="mt-n4">
        <v-col 
          :cols="isMobileProp ? 12 : 12"
          :sm="isMobileProp ? 12 : 4"
          class="px-2">
          <v-data-table
            v-if="store_Pessoa.acao !== 'I'"
            class="rounded-lg tabela mb-9 mt-n9"
            flat
            :items="store_Pessoa.pessoa_edicao.pessoa_socios1"
            :headers="headers_socios"
            :loading="loading"
            :items-per-page="10"
            hide-default-header
            hide-default-footer
            loading-text="Carregando...  aguarde..."
            no-data-text="Nenhum Registro Encontrado"
            no-results-text="Nenhum Registro Encontrado">
            <template v-slot:top>
              <v-toolbar
                flat
                class="rounded-lg">
                <p class="font-primary font-weight-bold font-weight-medium body-1 text-center mt-2" v-bind:style="{ 'color':COR_SUBTITULO }">
                  Sócios
                </p>  
                <v-spacer></v-spacer>
                <v-btn 
                  @click="store_Pessoa.dialogConjuge = true"
                  class="btn pr-6" elevation="0">
                  <v-icon class="btn-icon-plus mr-1" color="green accent-4">mdi-plus</v-icon>
                  <span class="caption font-weight-medium" v-bind:style="{ 'color':COR_SUBTITULO }">Novo</span>
                </v-btn>
              </v-toolbar>
            </template>
            <template #item="{ item }">
              <tr>
                <td>
                  <span :style="item.Pessoa_Socio.pessoa_socio_titular == 'S' ? 'font-weight: bold;' : ''" >  {{ item.pessoa_nome }}</span>
                  <br>
                  Porcentagem: {{ item.Pessoa_Socio.pessoa_socio_porc }}%
                </td>
                <td class="td-edit" width="5%">
                  <v-btn
                    @click="dialog_editar_socio = true; item_editar_socio_aux = item; editar_porc_aux = item.Pessoa_Socio.pessoa_socio_porc; select_socio_aux = item.Pessoa_Socio.pessoa_socio_titular"
                    icon 
                    dark 
                    class="btn-icon mr-5" 
                    color="grey">
                    <v-icon class="icon-action"> mdi-pencil </v-icon>
                  </v-btn>
                  <br>
                    <v-btn
                      @click="dialog_excluir_socio = true; item_excluir_socio_aux = item" 
                      icon 
                      dark 
                      class="btn-icon mr-5" 
                      color="grey">
                      <v-icon class="icon-action"> mdi-delete </v-icon>
                    </v-btn>
                </td>
              </tr> 
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <!-- FIM TABELA SOCIOS -------------------------------------------------------->
        
      <!-- Dialogo excluir socio -->
      <v-container
        v-if="dialog_excluir_socio">
        <v-dialog v-model="dialog_excluir_socio" transition="dialog-bottom-transition" persistent max-width="410" class="pa-0">
            <v-divider></v-divider>
            <v-card elevation="0" class="">
              <v-card-title class="text-h6">
                Tem certeza que deseja EXCLUIR este sócio?
              </v-card-title>
              <v-card-actions class="d-flex justify-center">
                <v-spacer></v-spacer>
                <v-btn class="mr-4" color="primary" text @click="dialog_excluir_socio = false">
                  Cancelar
                </v-btn>
                <v-btn 
                  class="btn white--text" 
                  color="primary accent-4" 
                  :loading="loading"
                  @click="excluirSocio()">
                  Excluir
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
      </v-container>
      <!-- Fim Dialogo excluir socio -->

      <!-- Dialogo Editar Socio -->
      <v-container
        v-if="dialog_editar_socio">
        <v-dialog
            v-model="dialog_editar_socio"
            scrollable
            max-width="500px"
            max-height="600px"
            persistent>
            <v-card>
              <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }">
                <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
                  <div class="pa-0" style="width:100%">
                    <p style="color:white; white-space: pre-line; font-size:16px !important;">
                      Alterar porcentagem 
                    </p>                      
                  </div>
                  <div class="pa-0 text-center" style="width:40px;">
                    <v-btn icon dark v-on:click="dialog_editar_socio = false; select_socio_aux = null" class="mt-n2">
                      <v-icon large class="title">mdi-close</v-icon>
                    </v-btn>
                  </div>
                </v-layout>
              </v-card-title>
              <v-card-text >
                <v-row>
                  <v-col 
                    cols="12"
                    sm="12"
                    class="mb-n4"
                    style="margin-top: 50px">
                    <v-text-field
                      v-model="editar_porc_aux"
                      class="input-inscricao mb-0"
                      background-color="#FFF"
                      label="Porcentagem"
                      placeholder="Porcentagem"
                      filled
                      outlined
                      dense
                      :rules="porcentagemRules">
                    </v-text-field>
                  </v-col>
                  <v-col 
                    cols="12"
                    sm="12"
                    class="mt-n2 mb-n2">
                    <v-select
                      v-model="select_socio_aux"
                      :items="arraySIM_NAO"
                      placeholder="Titular?"
                      label="Titular?"
                      item-text="name"
                      item-value="value"
                      required
                      outlined
                      dense
                      :rules="titularRules">
                    </v-select>
                </v-col>
              </v-row>
              </v-card-text>
              <v-card-actions class="d-flex justify-center pa-3" v-bind:style="{ 'background-color':COR_SECUNDARIA }">
                <v-btn
                  @click="dialog_editar_socio = false; select_socio_aux = null"
                  class="mr-4 btn text-none"
                  color="primary"
                  text>
                  Cancelar
                </v-btn>
                <v-btn
                  :loading="loading"
                  @click="editarSocio()"
                  class="btn text-none"
                  color="primary"
                  :disabled="!store_Pessoa.pessoa_conjuge_selecionado">
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
      </v-container>
      <!-- Fim Dialog Editar Socio -->

      <!-- Cadastro de endereço -->
      <v-row class="mt-n8">
        <v-col 
          :cols="isMobileProp ? 12 : 12"
          :sm="isMobileProp ? 12 : 4"
          class="px-5">
          <PessoaEnderecosCadastro 
            :dados_modal_endereco.sync="dados_modal_endereco"
            :dados_modal_municipio.sync="arrayMunicipios"
          />
        </v-col>
      </v-row>
      <!-- Fim Cadastro de endereço -->

    </v-form>
    <!-- FIM FORM CADASTRO -->

    <!-- DIALOGO SOCIO -------------------------------------------------------->
    <v-container
      v-if="store_Pessoa.dialogConjuge">
      <v-dialog
        v-model="store_Pessoa.dialogConjuge"
        scrollable
        max-width="500px"
        max-height="600px"
        persistent>
        <v-card>
          <!-- Cabecalho do Socio -->
          <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }">
            <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
              <div class="pa-0" style="width:100%">
                <p style="color:white; white-space: pre-line; font-size:16px !important;">
                  Busca de Sócios
                </p>                      
              </div>
              <div class="pa-0 text-center" style="width:40px;">
                <v-btn icon dark v-on:click="store_Pessoa.dialogConjuge = false; store_Pessoa.pessoa_conjuge_selecionado = {}" class="mt-n2">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-card-title>
          
          <!-- Corpo do Socio -->
          <v-card-text class="pa-0" style="height: 500px;">
            <PessoaConsultaSocioMobile
            class="mt-n2"
            :editar_="true"/>
          </v-card-text>

          <v-divider></v-divider>
        
          <!-- Rodape do Socio -->
          <v-card-actions class="d-flex justify-center pa-3" v-bind:style="{ 'background-color':COR_SECUNDARIA }">
            <v-btn
              @click="store_Pessoa.dialogConjuge = false; store_Pessoa.acao_abas = false; store_Pessoa.pessoa_conjuge_selecionado = {}"
              class="mr-4 btn text-none"
              color="primary"
              text>
              Cancelar
            </v-btn>
            <v-btn
              :loading="loading"
              class="btn text-none"
              color="primary"
              :disabled="JSON.stringify(store_Pessoa.pessoa_conjuge_selecionado) == '{}'"
              @click="dialog_titular = true">
              Selecionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <!-- FIM DIALOGO Socio ---------------------------------------------------->

    <!-- Dialogo adicionar Socio -->
    <v-container
      v-if="dialog_titular">
      <v-dialog
          v-model="dialog_titular"
          scrollable
          max-width="500px"
          max-height="600px"
          persistent>
          <v-card>
            <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }">
              <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
                <div class="pa-0" style="width:100%">
                  <p style="color:white; white-space: pre-line; font-size:16px !important;">
                    Titular
                  </p>                      
                </div>
                <div class="pa-0 text-center" style="width:40px;">
                  <v-btn icon dark v-on:click="dialog_titular = false; select_socio_aux = null" class="mt-n2">
                    <v-icon large class="title">mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-layout>
            </v-card-title>
            <v-card-text >
              <v-form
                v-model="validAdicionar"
                ref="formAdicionar"
                class="mx-1 mt-3">
                <v-row>
                  <v-col 
                  cols="12"
                    sm="12"
                    class="mb-n4"
                    style="margin-top: 50px">
                    <v-text-field
                      v-model="editar_porc_aux"
                      class="input-inscricao mb-0"
                      background-color="#FFF"
                      label="Porcentagem"
                      placeholder="Porcentagem"
                      filled
                      outlined
                      dense
                      :rules="porcentagemRules">
                    </v-text-field>
                  </v-col>
                  <v-col 
                    cols="12"
                    sm="12"
                    class="mt-n2 mb-n2">
                    <v-select
                      v-model="select_socio_aux"
                      :items="arraySIM_NAO"
                      placeholder="Titular?"
                      label="Titular?"
                      item-text="name"
                      item-value="value"
                      required
                      outlined
                      dense
                      :rules="titularRules">
                    </v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center pa-3" v-bind:style="{ 'background-color':COR_SECUNDARIA }">
              <v-btn
                @click="dialog_titular = false; select_socio_aux = null"
                class="mr-4 btn text-none"
                color="primary"
                text>
                Cancelar
              </v-btn>
              <v-btn
                :loading="loading"
                @click="adicionarSocio(store_Pessoa.pessoa_conjuge_selecionado); dialog_titular = false"
                class="btn text-none"
                color="primary"
                :disabled="!validAdicionar">
                Selecionar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>
    <!-- Fim Dialogo adicionar Socio -->
  
    <v-footer 
      color="transparent"
      elevation="0"
      class="footer justify-center mt-3 pt-3">
      <v-btn
        @click="cancelar()"
        id="btn_cancelar"
        ref="btn_cancelar"
        class="mr-4 caption font-weight-medium"
        color="primary"
        text>
        Cancelar
      </v-btn>

      <v-btn v-if="store_Pessoa.acao != 'C'"
        :disabled="!valid"
        :loading="loading"
        @click="validate()"
        class="btn caption font-weight-medium white--text"
        color="primary accent-4">
        Salvar
      </v-btn>
    </v-footer>

    <!-- Arquivo/Webcam Dialog ------------------------------------------ -->
    <v-dialog
      v-if="dialog_crop"
        :value="true"
        persistent
        style="width: 330"
        :max-width="330"
        :retain-focus="false">
      <v-card>
        <v-card-title>Foto</v-card-title>
        <v-card-text class="pt-7">
          <VueCropImage
            :img="store_Pessoa.pessoa_edicao.pessoa_foto"
            :imgsync.sync="store_Pessoa.pessoa_edicao.pessoa_foto"
            :dialog_crop.sync="dialog_crop"
            ref="VueCropImage"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Fim Arquivo/Webcam Dialog ------------------------------------- -->

  </div>
</template>

<script>
import moment from 'moment'
import store_Pessoa from "./store_Pessoa";
import store_site from "../../store/store_site";
import {
  formatDate,
  maskCpfCnpj,
  CpfCnpjIsValid,
} from "../../services/funcoes";
import store_ModalNegociacao from "../ModalNegociacao/store_ModalNegociacao";
import { COR_PRINCIPAL, arraySIM_NAO, COR_SUBTITULO, COR_SECUNDARIA, MASK_CPFCNPJ, MASK_TELEFONE, arraySexo, arrayEstadoCivil, arrayPaises, arrayRegimeBens } from "../../services/constantes";
import { nomeIniciais } from "../../services/funcoes"
import { API } from "../../services/API"
import DatePicker from "../ComponentesGlobais/Date-Picker/date-picker.vue";
import VueCropImage from "../ComponentesGlobais/CropImage/VueCropImage.vue";
import PessoaConsultaSocioMobile from "./PessoaConsultaSocioMobile.vue"
import PessoaEnderecosCadastro from "./Enderecos/PessoaEnderecosCadastro.vue";
import { nextTick } from 'vue';


export default {
  name: "PessoaCadastroJuridicaMobile",

  props: {
    isMobileProp: {
      type    : Boolean,
      default : false
    },
     itemDataTable: {
      type    : Object,
    }
  },

  components: {
    VueCropImage,
    DatePicker,
    PessoaEnderecosCadastro,
    PessoaConsultaSocioMobile,
  },

  data() {
    return {
      store_Pessoa          : store_Pessoa,
      store_site            : store_site,
      store_ModalNegociacao : store_ModalNegociacao,

      maskCpfCnpj   : maskCpfCnpj,
      formatDate    : formatDate,
      nomeIniciais  : nomeIniciais,

      MASK_CPFCNPJ      : MASK_CPFCNPJ,
      MASK_TELEFONE     : MASK_TELEFONE,
      isMobile          : false,
      img               : null,
      imgsync           : null,
      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO,  
      COR_SECUNDARIA    : COR_SECUNDARIA,
      
      arraySexo              : arraySexo,
      arrayEstadoCivil       : arrayEstadoCivil,
      arrayPaises            : arrayPaises,
      arrayMunicipios        : null,
      arrayRegimeBens        : arrayRegimeBens,
      arraySIM_NAO           : arraySIM_NAO,
      select_socio_aux       : null,
      item_editar_socio_aux  : null,
      item_excluir_socio_aux : null,
      
      dialog_crop            : false,
      dialog_excluir         : false,
      editar_porc_aux        : null,
      dialog_titular         : false,
      dialog_editar_socio    : false,
      dialog_excluir_socio   : false,
      dados_modal_endereco   : {},
      
      valid                 : false,
      validAdicionar        : false,
      loading               : false,

      conjuge               : { cod_pessoa       : null,
                                pessoa_nome      : null,
                                pessoa_cpf       : null,
                                pessoa_nasc_dt   : null,
                                pessoa_profissao : null
                              },

      /* Títulos Tabela Sócios*/
      headers_socios: [
        { text: "Sócio", value: "Sócio", class: 'title-text' },
        { text: "Porcentagem %", value: "Porcentagem %", class: 'title-text' },
      ],

      /* Tabela Sócios */
      array_socios: [],

      //Rules ----------------------------------------------------------------------
      rules: {
        min(min, v) { 
          return (v || '').length >= min || `Mínimo de ${min} caracteres`;
        },
        max(max, v) { 
          return (v || '').length <= max || `Máximo de ${max} caracteres`;
        },
        data(v) { 
          return (v || '').length >= 10 || `Mínimo de 10 caracteres`;
        },
      },
      razaoRules: [
        (value) => !!value || "A Razão Social é obrigatória",
        (value) =>
          (value && value.length >= 3) ||
          "A Razão Social deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "A Razão Social deve ter menos do que 60 caracteres",
      ],
      inscricaoRules:[
      (value) =>
          (!value || value.length <= 25) || "A Inscrição deve ter menos do que 25 caracteres",
      ],
      cnaeRules:[
      (value) =>
          (!value || value.length <= 25) || "O Cnae deve ter menos do que 25 caracteres",
      ],
      ramoNegocioRules:[
      (value) =>
          (!value || value.length <= 15) || "O Ramo Negócop deve ter menos do que 15 caracteres",
      ],
      porcentagemRules:[
      (value) => !!value || "A porcentagem é obrigatória",
      (value) =>
        (!value || value.length <= 3) || "A porcentagem deve ter até 3 caracteres",
      (value) => 
        ( !value ||  /^\d+$/.test(value)) || 'A porcentagem não é válida',
      ],
      titularRules:[
      (value) => !!value || "O titular é obrigatório",
      ],
      dataRules: [
        (value) => 
          ((!value || value.length <= 0) || (value && moment(value, 'DD/MM/YYYY', true).isValid())) || "A Data não é válida",
        (value) =>
          (!value || value.length <= 10) || "A Data deve ter menos do que 10 caracteres",
      ],
      // estadocivilRules: [
      //   (value) => !!value || "O Estado Civil é obrigatório",
      // ],
      cnpjRules: [
        (value) => !!value || "O CNPJ é obrigatório",
        (value) => (value && CpfCnpjIsValid(value)) || "O CNPJ não é válido",
        (value) =>
          (value && value.length <= 18) ||
          "O CNPJ deve ter 18 caracteres",
      ],
    };
  },

  created() {
    //if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    //    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4)))
    { 
      this.isMobile = true;
    }
    //if (this.isMobileProp)
    { 
      this.isMobile = true;
    }
  },

  computed: {
    textMunicipio() {
      return this.store_Pessoa.pessoa_edicao?.pessoa_municipio?.nome + " - " + this.store_Pessoa.pessoa_edicao?.pessoa_municipio?.uf
    },
  },

  async mounted() {
    // Filtra somente o endereco de correspondencia quando ja existe o endereço
    if (this.store_Pessoa.pessoa_edicao.pessoa_enderecos && this.store_Pessoa.pessoa_edicao.pessoa_enderecos.length > 0) {
      var aux = this.store_Pessoa.pessoa_edicao.pessoa_enderecos.filter((valor) =>  
        valor.pessoa_endereco_corresp == "S"
        )

        if(aux.length <= 0){
          this.dados_modal_endereco = this.store_Pessoa.pessoa_edicao.pessoa_enderecos[0];
        }
        else{
          this.dados_modal_endereco = aux[0];
        }
        //  [this.dados_modal_endereco.pessoa_endereco_municipio].forEach(element => {
        //   element.nome = element.nome + ' - ' + element.uf
        // });
        // this.arrayMunicipios = this.dados_modal_endereco.pessoa_endereco_municipio
        //   console.log('this.arrayMunicipios', this.arrayMunicipios);
        this.arrayMunicipios = [this.dados_modal_endereco.pessoa_endereco_municipio]

    }
  },

  // destroyed() {
  //   this.selecionarConjuge();
  // },

  methods: {
    async adicionarSocio(){
      const lb_valido_adicionar = this.$refs.formAdicionar.validate();
      if(lb_valido_adicionar){
        this.loading = true

        //Verifica se o socio já está vinculado a este cadastro
        for(let value of this.store_Pessoa.pessoa_edicao.pessoa_socios1){
          if(value.Pessoa_Socio.cod_pessoa_socio ==  this.store_Pessoa.pessoa_conjuge_selecionado.cod_pessoa){
            this.store_site.alert_cor       = "#A00000";
            this.store_site.alert_timeout   = 20000;
            this.store_site.alert_msg       = "Sócio já cadastrado";
            this.store_site.alert           = true;
            this.loading = false
            return
          }
        }
  
        //Monta os dados para fazer a requisição
        let aux = {};
        aux.cod_pessoa_juridica = this.store_Pessoa.pessoa_edicao.cod_pessoa;
        aux.cod_pessoa_socio = this.store_Pessoa.pessoa_conjuge_selecionado.cod_pessoa;
  
        //Verifica se foi digitado a porcentagem
        if(!this.editar_porc_aux){
          aux.pessoa_socio_porc = 0
        }else{
          aux.pessoa_socio_porc = this.editar_porc_aux;
        }
  
        //Verifica se foi informado se é titular
        if(!this.select_socio_aux){
          aux.pessoa_socio_titular = "N"
        }else{
          aux.pessoa_socio_titular = this.select_socio_aux;
        }

        if (this.store_Pessoa.pessoa_edicao.pessoa_socios1.length == 0) {
          aux.pessoa_socio_titular = 'S'
          // console.log("aux", aux);
        }
  
        let lo_Socio = {...aux, acao: "I"};
        let ls_Socio = await this.store_Pessoa.PessoaSocioPost(lo_Socio)
  
        //Atualiza a tabela com os dados novos
        let auxiliar = {};
        auxiliar = { Pessoa_Socio: aux }
        auxiliar.pessoa_nome = this.store_Pessoa.pessoa_conjuge_selecionado.pessoa_nome
        this.store_Pessoa.pessoa_edicao.pessoa_socios1.push(auxiliar);
        if(this.select_socio_aux == "S"){
          for(let value of this.store_Pessoa.pessoa_edicao.pessoa_socios1){
            if(value.Pessoa_Socio.cod_pessoa_socio != this.store_Pessoa.pessoa_conjuge_selecionado.cod_pessoa){
              value.Pessoa_Socio.pessoa_socio_titular = "N";
            }
          }
        }
  
        //Atualiza o banco com os novos dados do titular
        let novo_lo_Socio
        let novo_ls_Socio
        if(this.select_socio_aux == "S"){
          for(let value of this.store_Pessoa.pessoa_edicao.pessoa_socios1){
            if(value.Pessoa_Socio.cod_pessoa_socio != this.store_Pessoa.pessoa_conjuge_selecionado.cod_pessoa){
              value.Pessoa_Socio.pessoa_socio_titular = "N";
              let novoValue = value.Pessoa_Socio;
              novo_lo_Socio = {...novoValue, acao: "E"}
              novo_ls_Socio = await this.store_Pessoa.PessoaSocioPost(novo_lo_Socio)
            }
          }
        }
  
        //Retorno do backend
        if ((ls_Socio.result && ls_Socio.result.message && ls_Socio.result.message == 'success') ||
            (ls_Socio.status && ls_Socio.status == 200)) {
            this.store_site.alert_cor       = "#00A000";
            this.store_site.alert_timeout   = 1500;
            this.store_site.alert_msg       = ls_Socio.result.result.trim();
            this.store_site.alert           = true;
          } else {
            this.store_site.alert_cor       = "#A00000";
            this.store_site.alert_timeout   = 20000;
            this.store_site.alert_msg       = ls_Socio.errors.trim();
            this.store_site.alert           = true;
          }
  
        this.loading = false
        this.store_Pessoa.dialogConjugeExcluir  = false
        this.store_Pessoa.dialogConjuge         = false
      }
    },

    async excluirSocio(){
      this.loading = true
      //Monta os dados para fazer a requisição
      let aux = {};
      aux.cod_pessoa_socio = this.item_excluir_socio_aux.Pessoa_Socio.cod_pessoa_socio
      aux.cod_pessoa_juridica = this.item_excluir_socio_aux.Pessoa_Socio.cod_pessoa_juridica;
      let lo_Socio = {...aux, acao: "D"};
      let ls_Socio = await this.store_Pessoa.PessoaSocioPost(lo_Socio)

      //Atualiza a tabela com os novos dados
      this.store_Pessoa.pessoa_edicao.pessoa_socios1 = this.store_Pessoa.pessoa_edicao.pessoa_socios1.filter(value =>
        value.Pessoa_Socio.cod_pessoa_socio != this.item_excluir_socio_aux.Pessoa_Socio.cod_pessoa_socio
      )

        //verifica se existe 1 socio como titular
      let seta_socio_como_titular = false
      if (this.store_Pessoa.pessoa_edicao.pessoa_socios1.length > 0) {
        for(let value of this.store_Pessoa.pessoa_edicao.pessoa_socios1){
          if(value.Pessoa_Socio.pessoa_socio_titular == 'S'){
            seta_socio_como_titular = false
          }
          else{
            seta_socio_como_titular = true
          }
        }
        // se não existe nenhum socio como titular, seta o primeiro
        if (seta_socio_como_titular) {
          let titular = {}
          titular = this.store_Pessoa.pessoa_edicao.pessoa_socios1[0].Pessoa_Socio
          titular.pessoa_socio_titular = 'S'
          let lo_socio_titular = {...titular, acao: "E"};

          let ls_Socio = await this.store_Pessoa.PessoaSocioPost(lo_socio_titular)
        }
      }

      //Retorno do backend
      if ((ls_Socio.result && ls_Socio.result.message && ls_Socio.result.message == 'success') ||
          (ls_Socio.status && ls_Socio.status == 200)) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = ls_Socio.result.result.trim();
          this.store_site.alert           = true;
        } else {
          this.store_site.alert_cor       = "#A00000";
          this.store_site.alert_timeout   = 20000;
          this.store_site.alert_msg       = ls_Socio.errors.trim();
          this.store_site.alert           = true;
        }

      this.loading = false;
      this.dialog_excluir_socio = false;
    },

    async editarSocio(){
      this.loading = true
      //Monta os dados para fazer a requisição
      let aux = {};
      aux.cod_pessoa_socio = this.item_editar_socio_aux.Pessoa_Socio.cod_pessoa_socio
      aux.cod_pessoa_juridica = this.item_editar_socio_aux.Pessoa_Socio.cod_pessoa_juridica;

      //Verifica se foi digitado a porcentagem
      if(!this.editar_porc_aux){
        aux.pessoa_socio_porc = 0
      }else{
        aux.pessoa_socio_porc = this.editar_porc_aux;
      }

      //Verifica se foi informado se é titular
      if(!this.select_socio_aux){
        aux.pessoa_socio_titular = "N"
      }else{
        aux.pessoa_socio_titular = this.select_socio_aux;
      }

      let lo_Socio = {...aux, acao: "E"};
      let ls_Socio = await this.store_Pessoa.PessoaSocioPost(lo_Socio)

      //Atualiza a tabela com os novos dados do titular
      for(let value of this.store_Pessoa.pessoa_edicao.pessoa_socios1){
        if(value.Pessoa_Socio.cod_pessoa_socio == this.item_editar_socio_aux.Pessoa_Socio.cod_pessoa_socio){
          value.Pessoa_Socio.pessoa_socio_porc = Number(this.editar_porc_aux);
          value.Pessoa_Socio.pessoa_socio_titular = this.select_socio_aux;
        }
      }

      //Atualiza o banco com os novos dados do titular
      let novo_lo_Socio
      let novo_ls_Socio
      if(this.item_editar_socio_aux.Pessoa_Socio.pessoa_socio_titular == "S"){
        for(let value of this.store_Pessoa.pessoa_edicao.pessoa_socios1){
          if(value.Pessoa_Socio.cod_pessoa_socio != this.item_editar_socio_aux.Pessoa_Socio.cod_pessoa_socio){
            value.Pessoa_Socio.pessoa_socio_titular = "N";
            let novoValue = value.Pessoa_Socio;
            novo_lo_Socio = {...novoValue, acao: "E"}
            novo_ls_Socio = await this.store_Pessoa.PessoaSocioPost(novo_lo_Socio)
          }
        }
      }

      //Retorno do backend
      if ((ls_Socio.result && ls_Socio.result.message && ls_Socio.result.message == 'success') ||
          (ls_Socio.status && ls_Socio.status == 200)) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = ls_Socio.result.result.trim();
          this.store_site.alert           = true;
        } else {
          this.store_site.alert_cor       = "#A00000";
          this.store_site.alert_timeout   = 20000;
          this.store_site.alert_msg       = ls_Socio.errors.trim();
          this.store_site.alert           = true;
        }
  
      this.loading = false
      this.dialog_editar_socio = false;
    },

    cancelar() {
      this.store_Pessoa.dialog_cadastro_mobile = false;
      this.store_Pessoa.dialog_cadastro_juridica_mobile = false;
      this.store_Pessoa.acao = 'C';
      this.store_Pessoa.pessoa_edicao = {};
      this.dados_modal_endereco = {};
      this.arrayMunicipios = [];
      this.store_Pessoa.pessoa_leads_edicao = false
    },

    async validate() {
      const lb_valido = this.$refs.form.validate();
      if (lb_valido) {
        this.loading = true;

        //Monta os dados para fazer a requisição
        this.store_Pessoa.pessoa_edicao.pessoa_endereco_corresp = "S"
        this.store_Pessoa.pessoa_edicao.pessoa_fisica_juridica = "Jurídica"
        var lo_JSON = { ...this.store_Pessoa.pessoa_edicao };
        // console.log('lojson', lo_JSON);
        var ls_Res;

        if (this.store_Pessoa.acao == "E") {
          lo_JSON.cod_conjuge = this.conjuge.cod_pessoa ?? null
          ls_Res = await this.store_Pessoa.PessoaPut(lo_JSON);
          // console.log('edicao', ls_Res);
        }
        else{
          ls_Res = await this.store_Pessoa.PessoaPost(lo_JSON);
          // console.log('else', ls_Res);

        }

        //Retorno do backend
        if ((ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') ||
            (ls_Res.status && ls_Res.status == 200)) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = ls_Res.result.result.trim();
          this.store_site.alert           = true;

            // salvando os dados de negociação da pessoa em Qualificação/Leads/Propostas/Reservas
          // this.itemDataTable.cliente_nome =  this.store_Pessoa.pessoa_edicao.pessoa_nome
          // this.itemDataTable.cliente_cpf =  this.store_Pessoa.pessoa_edicao.pessoa_cpf
          // this.itemDataTable.cliente_celular =  this.store_Pessoa.pessoa_edicao.fone_celular1
          // this.itemDataTable.cliente_email =  this.store_Pessoa.pessoa_edicao.pessoa_email
          // this.itemDataTable.cliente_cnpj =  this.store_Pessoa.pessoa_edicao.pessoa_cnpj

        } else {
          this.store_site.alert_cor       = "#A00000";
          this.store_site.alert_timeout   = 20000;
          this.store_site.alert_msg       = ls_Res.errors.trim();
          this.store_site.alert           = true;
        }

        // Verificação se o usuario digitou endereço nos inputs
        if(this.dados_modal_endereco.pessoa_endereco_cep){
          var cod_pessoa_salva = ls_Res.result.data.cod_pessoa;
          
          //  Verificação se a pessoa ja possui endereço cadastrado ou não
          if(this.dados_modal_endereco.cod_pessoa_endereco){
            this.store_Pessoa.acao = 'E';
          }
          else{
            this.store_Pessoa.acao = 'I';
          }

          //Requisição para salvar o endereço
          var lo_endereco = {...this.dados_modal_endereco, cod_pessoa: cod_pessoa_salva, acao: this.store_Pessoa.acao}
          var ls_Endereco = await this.store_Pessoa.PessoaEnderecoPost(lo_endereco)
         
        }

        this.loading = false;
        this.store_Pessoa.acao = 'C';
        this.store_Pessoa.dialog_cadastro_mobile = false;
        this.store_Pessoa.dialog_cadastro_juridica_mobile = false;
        this.store_Pessoa.preenche_search_pessoa_cadastrada = this.store_Pessoa.pessoa_edicao.pessoa_nome

        this.store_Pessoa.pessoa_edicao = {};

        this.store_ModalNegociacao.dialog_novo_lead = false
        this.$nextTick(() => {
          this.store_ModalNegociacao.dialog_novo_lead = true
        })
      }
    },

    /* Método que recebe arquivo de imagem */
    handleFile({ target }) {
      this.dialog_crop = true;
    },

    togglePlus () {
      this.plus = !this.plus
    },

    // selecionarConjuge(elem) {
    //   if (!elem) {
    //     this.conjuge = {  cod_pessoa       : null,
    //                       pessoa_nome      : null,
    //                       pessoa_cpf       : null,
    //                       pessoa_nasc_dt   : null,
    //                       pessoa_profissao : null
    //                     }
    //     this.store_Pessoa.pessoa_edicao.pessoa_casamento_dt = null;
    //     this.store_Pessoa.pessoa_edicao.cod_regime          = null;
    //     this.store_Pessoa.pessoa_edicao.pessoa_conjuge      = {...this.conjuge};
    //     this.store_Pessoa.pessoa_edicao.cod_conjuge         = null;
    //   } 
    //   else {
    //     var novoDado = { socio: this.store_Pessoa.pessoa_conjuge_selecionado.pessoa_nome}

    //     this.array_socios.push(novoDado)
        
    //     this.conjuge = elem;
    //     this.store_Pessoa.pessoa_edicao.cod_conjuge = this.conjuge.cod_pessoa;
    //   }

    //   this.store_Pessoa.dialogConjugeExcluir  = false
    //   this.store_Pessoa.dialogConjuge         = false
    // },
  },
};
</script>

<style scoped>
  #PessoaCadastro {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    padding-top: 4px;
    padding-right: 8px;
    width: 100%;
  }

  @media(max-width: 599px) {
    #PessoaCadastro {
      padding-right: 0px;
    }
  }

  /* ---------- BARRA DE SCROLL ---------- */
  #PessoaCadastro::-webkit-scrollbar {
    width: 5px;
  }

  #PessoaCadastro::-webkit-scrollbar-button {
    padding: 1px;
  }

  #PessoaCadastro::-webkit-scrollbar-thumb {
    background: #CECECE; 
    border-radius: 50px;
  }

  #PessoaCadastro::-webkit-scrollbar-thumb:hover {
    background: #A7A7A7; 
    height: 150px;
  } 

  @media(max-width: 599px) {
    #PessoaCadastro::-webkit-scrollbar {
      width: initial;
    }

    #PessoaCadastro::-webkit-scrollbar-button {
      padding: initial;
    }

    #PessoaCadastro::-webkit-scrollbar-thumb {
      background: initial;
      border-radius: initial;
    }

    #PessoaCadastro::-webkit-scrollbar-thumb:hover {
      background: initial;
      height: initial;
    } 
  }

  .nav-link {
    text-decoration: none;
  }

  .btn {
    width: 100px;
  }

  .btn-icon {
    font-size: 17px;
  }

  .btn-icon-plus {
    font-size: 20px;
  }

  .icon-action {
    font-size: 20px!important;
    color: #9E9E9E;
  }

  .my-custom-dialog {
    align-self: center;
    position: absolute;
    bottom: 0;
    right: 0;
    color: red;
  }

  .flex-column {
    flex-direction: column!important;
  }

  .input-inscricao {
    margin-top: -22px;
  }

  @media(max-width: 599px) {
    .input-rg {
      margin-top: -8px;
    }

    .input-inscricao {
      margin-top: -10px;
    }

    .col-terceira-pf div:nth-child(2), .col-terceira-pj div:nth-child(2) {
      margin-top: -16px;
    }

    .col-terceira-pj div:nth-child(1) {
      margin-bottom: 4px;
    }

    .col-terceira-pj div:nth-child(3) {
      margin-bottom: -4px;
    }

    .col-quinta-pj div:nth-child(3) {
      margin-top: -12px;
    }

    .input-razao {
      margin-bottom: -12px!important;
    }

    .input-date {
      margin-top: -12px;
    }

    .input-conjuge, .input-date-nasc, .input-date-cas {
      margin-top: -8px;
    }
  }

  .footer {
    width: 100%;
    position: sticky;
    bottom: 0;
    padding: 2px;
    background: var(--COR_SECUNDARIA)!important;
    border-top-width: 1px!important;
    border-top-style: solid!important;
    border-top-color: var(--COR_SOMBRA)!important;
  }

</style>