<template>
  <v-container id="PessoaConsultaMobile" class="pa-0 ma-0" v-bind:style="{ 'background-color':COR_BACKGROUND_TEXT }">

    <!-- Botões de ação cabeçalho -->
    <v-toolbar-title 
      v-bind:style="{ 'background-color':COR_PRINCIPAL }"
      class="d-flex align-center" tabindex="-1"
      dark
      elevation="0"
    >
      <v-text-field
        id="txtBuscar"
        ref="txtBuscar"
        v-on:keyup="filtro = $event.target.value"
        prepend-inner-icon="mdi-magnify"
        dense
        v-model="search"
        cache-items
        class="ml-3 search-input"
        v-bind:class="{'search-input-longo': search_input_focus}"
        flat
        dark
        label=""
        solo-inverted
        clearable
        @focus="onFocus($event)"
        @blur="onBlur($event)"
        tabindex="-1"
      >
      </v-text-field>

      <v-btn 
        @click="Novo()"
        class="mr-3 btn-new text-none"
        :color="COR_PRINCIPAL"
        elevation="0">
        <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
        <span>Novo</span>
      </v-btn>

    </v-toolbar-title>

    <!-- Help -->
    <v-expansion-panels
      class="pa-0 pt-0 px-0 expansion-panel d-none"
      accordion
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          class="panel-header font-weight-bold"
          disable-icon-rotate
        >
          Documentos Básicos
          <template v-slot:actions>
            <v-icon color="" class="icon-help">
              mdi-help-circle-outline
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="pt-4">
          Conteúdo teste
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- End Accordion -->
    <!-- Container Documentos Básicos -->
    <!-- <v-container
      class="container-table container-content mb-3 pt-0 px-0 mt-n6"
      style="max-width: 1200px"
    > -->
    <!-- Fim Help -->

    <v-container class="pa-0 mb-16">
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="dados"
            single-select
            show-select
            fixed-header
            top="0px"
            hide-default-header
            hide-default-footer
            loading-text="Carregando...  aguarde..."
            no-data-text="Nenhum Registro Encontrado"
            no-results-text="Nenhum Registro Encontrado"
          >
            <template #item="{ item }">
                <tr :search="search">
                  <td class="td-height" >
                    <v-checkbox
                      v-on:change="seleciona($event, item)"
                      :value="item.cod_pessoa == store_ModalNegociacao.pessoa_conjuge_selecionado.cod_pessoa"
                      :key="item.cod_pessoa"
                      hide-details
                      class="mr-n6"
                      style="margin-top: -50%;" />
                  </td>

                  <td class="hide-sm py-2" style="width: 100%">
                    <span style="font-size: 15px; display:block; color:#385986 !important;" class="font-weight-bold">{{ item.pessoa_nome }}</span>
                    <span style="font-size: 12px; display:block; color:#808080 !important;" v-if="item.pessoa_cpf">CPF: {{ maskCpfCnpj(item.pessoa_cpf) }}</span>
                    <span style="font-size: 12px; display:block; color:#808080 !important;" v-if="item.pessoa_cnpj">CNPJ: {{ maskCpfCnpj(item.pessoa_cnpj) }}</span>
                    <span style="font-size: 12px; display:block; color:#808080 !important;" >{{ item.fone_celular1 }}</span>
                    <span style="font-size: 12px; display:block; color:#808080 !important;" >{{ item.pessoa_email }}</span>
                  </td>

                  <!-- <td
                  class="mobile hide-sm"
                  >
                    <v-btn 
                    v-on:click="Editar(item.cod_pessoa)"
                    icon dark 
                    class="btn-icon ml-n1 mr-n1"
                    color="grey">
                      <v-icon class="icon-action"> mdi-pencil </v-icon>
                    </v-btn>
                  </td> -->
                  
                </tr>
            </template>
          </v-data-table>

        </v-container>


    <!-- <v-container class="pa-0 mb-16">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="dados"
        single-select
        show-select
        fixed-header
        top="0px"
        hide-default-header
        hide-default-footer
        loading-text="Carregando...  aguarde..."
        no-data-text="Nenhum Registro Encontrado"
        no-results-text="Nenhum Registro Encontrado"
        
      >
        <template #item="{ item }">
          <tr 
            :search="search"
          >
            <td >
              <v-checkbox
                v-on:change="seleciona($event, item)"
                :value="item.cod_contato == store_ModalNegociacao.contato_selecionado.cod_contato"
                :key="item.cod_contato"
                hide-details
                class="mr-n6"
                  />
            </td>

            <td class="hide-sm py-2">
              <span style="color:#385986 !important; display:block" class="font-weight-bold">{{ item.contato_nome }}</span>
              <span style="display:block; font-size: 13px !important;" >CPF: {{ item.contato_cpfcnpj }}</span>
              <span style="display:block; font-size: 13px !important;" >{{ item.contato_celular }}</span>
              <span style="display:block; font-size: 13px !important;" >{{ item.contato_email }}</span>
            </td>

            <td class="hide-sm mobile">
              <v-btn 
                  v-on:click="Editar(item.cod_contato)"
                  icon
                  dark 
                  class="btn-icon ml-n4" 
                  color="grey">
                <v-icon class="icon-action"> mdi-pencil </v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-container> -->

    <!-- NOVO MODAL USANDO VUETIFY -->
    <v-container
      v-if="store_Pessoa.dialog_cadastro_conjuge"
      class="px-0"
    >
      <v-dialog v-model="store_Pessoa.dialog_cadastro_conjuge"
                max-width="440"
                :retain-focus="false"
                persistent
                >
        <v-card class="px-0" v-bind:style="{ 'background-color':COR_BACKGROUND }">
          <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
            class="justify-space-between py-2 px-3">
            <span class="text-white title-page body-1">Cadastro de Sócio</span>
            <v-btn
              icon
              dark
              color="#F2F6F7"
              @click="store_Pessoa.dialog_cadastro_conjuge = false; store_Pessoa.pessoa_conjuge_edicao = {}">
              <v-icon large class="title">mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pt-2 px-2">
              <PessoaCadastroConjugeMobile
                :pessoa_edicao_prop="{}"
                :dados.sync="dados"
                ref="PessoaCadastroConjugeMobile"
                :isMobileProp="true"
                class="px-0"
                @busca_Pessoa="busca_Pessoa"
                />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
    <!-- NOVO MODAL USANDO VUETIFY -->

  </v-container>
</template>

<script>
import store_Pessoa from "./store_Pessoa";
import store_ModalNegociacao from "../ModalNegociacao/store_ModalNegociacao";
import store_Login from "../Login/store_Login"
import { maskCpfCnpj } from "../../services/funcoes";
import PessoaCadastroConjugeMobile from "./PessoaCadastroConjugeMobile.vue"
import { COR_PRINCIPAL, COR_BACKGROUND, COR_BACKGROUND_TEXT } from "../../services/constantes"

export default {
  name: "PessoaConsultaSocioMobile",

  components: {
    PessoaCadastroConjugeMobile,
  },

  props: {
    editar_ :{  type    : Boolean,
                default : false,
    }
  },

  data() {
    return {
      store_Pessoa          : store_Pessoa,
      store_ModalNegociacao : store_ModalNegociacao,
      store_Login           : store_Login,

      maskCpfCnpj           : maskCpfCnpj,

      COR_PRINCIPAL         : COR_PRINCIPAL,
      COR_BACKGROUND        : COR_BACKGROUND,
      COR_BACKGROUND_TEXT   : COR_BACKGROUND_TEXT,

      lb_timeout            : true,
      loading               : false,
      search                : null,

      selected              : [],
      checked               : false,
      search_input_focus    : false,

      dialog_cadastro_conjuge : false,

      dados                 : [],

      /* Título Tabela */
      headers: [
        { text: "", },
        { text: "", },
        { text: "Nome",  value: "nome", },
        { text: "Email", value: "email" },
        { text: "Telefone", value: "telefone" },
        { text: "", },
        { text: "", },
      ],
    };
  },

  mounted() {
    this.busca_Pessoa();
    if(this.store_Pessoa.preenche_search_pessoa_cadastrada != ''){
      this.search = this.store_Pessoa.preenche_search_pessoa_cadastrada
      this.store_Pessoa.preenche_search_pessoa_cadastrada = ''
    }

  },

  watch: {
    search(val) {
      this.busca_Pessoa_timeout(val);
    },
  },

  methods: {
    onBlur() {
        this.search_input_focus = false;
    },

    onFocus() {
        this.search_input_focus = true;
    },

    seleciona(value, item) {
      if (value) {
        this.store_ModalNegociacao.pessoa_conjuge_selecionado = item;
        this.store_Pessoa.pessoa_conjuge_selecionado          = item;
        this.store_Pessoa.pessoa_selecionado = item
        //console.log("this.store_ModalNegociacao.pessoa_conjuge_selecionado", this.store_ModalNegociacao.pessoa_conjuge_selecionado.pessoa_nome)
      }
      else {
        this.store_ModalNegociacao.pessoa_conjuge_selecionado = [];
        this.store_Pessoa.pessoa_conjuge_selecionado          = {};
      }
    },

    async busca_Pessoa(p_params) {
      this.loading = true;
      var lo_params = {};
      if (p_params)
        lo_params = { filtro: p_params };
      this.store_ModalNegociacao.pessoa_conjuge_selecionado = [];
      const lo_Res = await this.store_Pessoa.PessoaGet(lo_params);
      if (lo_Res && lo_Res.rows.length > 0)
        this.dados = lo_Res.rows;
      else
        this.dados = [];
      this.loading = false;
      this.lb_timeout = false;
    },

    async Editar(p_pessoa) {
      var params = {cod_pessoa : p_pessoa}
      this.store_Pessoa.pessoa_conjuge_edicao = {};
      this.store_Pessoa.pessoa_conjuge_edicao = await this.store_Pessoa.PessoaGet(params);
      this.store_Pessoa.dialog_cadastro_conjuge = true;
    },

    Novo() {
      this.store_Pessoa.acao = "I";
      this.store_Pessoa.pessoa_conjuge_edicao.pessoa_fisica_juridica = 'Física';
      this.store_Pessoa.pessoa_conjuge_selecionado = {}; 
      this.store_Pessoa.dialog_cadastro_conjuge = true;
    },

    busca_Pessoa_timeout(val) {
      if (val) {
        val = val.trim();
      }
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(() => {
        this.busca_Pessoa(val);
      }, 450);
    },
  },
};
</script>

<style scoped>
/*-----------------------------------*/
/*------ TABLE ------*/
/*-----------------------------------*/
.main-title {
  background: #0548a3;
}

@media (min-width: 1024px) {
  .container-title {
    max-width: calc(100% - 32px) !important;
  }
}

.text-white {
  font-family: "Montserrat", sans-serif !important;
  font-size: 20px;
  color: rgba(255,255,255,0.8);
}

.accordion-panel {
  box-shadow: none !important;
}

.expansion-panel {
  border-radius: 8px 8px 0px 0px;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

table tr {
  width: 100%;
}

.table-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
}

.table-row:hover {
  background: #f6f6f6 !important;
}

.panel-header {
  height: 60px;
  font-family: "Montserrat", sans-serif !important;
  font-size: 17px;
  background: #f6f6f6 !important;
  color: #4476ba;
}

.wrapper-title,
.v-toolbar,
.v-sheet {
  display: flex !important;
  flex-direction: column !important;
  padding: 0px !important;
}

.v-toolbar__content {
  padding-left: 0px !important;
}

.v-input__control {
  background: #2a63b1 !important;
}

  .search-input {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
    /* min-width: calc(100% - 15px); */
  }

  @media (max-width: 768px) {
    .search-input {
      margin: 0.8rem 10% 1.2rem 0;
    }
    .search-input-longo {
      margin: 0.8rem 10% 1.2rem 0;
      /* min-width: calc(100% - 15px); */

    }
  }

.v-input__control,
.v-input__slot,
.v-select__slot {
  height: 35px !important;
}

.v-label {
  margin-right: 10px !important;
}

.icon-action {
  font-size: 1.3rem !important;
}

@media (max-width: 768px) {
  .btn-icon {
    margin-right: 8px !important;
  }
}

.container-content {
  border-radius: 20px 20px !important;
  background: #fff !important;
}

@media (max-width: 768px) {
  .main-title {
    padding-right: 20px !important;
    padding-left: 20px !important;
  }

  .container-content {
    max-width: 740px !important;
  }
}

@media (max-width: 599px) {
  .container-content {
    padding: 16px !important;
  }
}

.container-table {
  max-width: calc(100% - 32px) !important;
  max-width: 400px !important;
  border-radius: 8px !important;
  background: #fff !important;
}

@media (max-width: 375px) {
  .container-table {
    max-width: 100% !important;
    border-radius: 16px !important;
  }
}

.wrapper-title div {
  padding-left: 0px !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1.5rem !important;
}

.status_ativo {
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_pendente {
  font-size: 14px !important;
  background: goldenrod;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.v-toolbar__title {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.wrapper-title {
  margin-bottom: 12px;
}

.row-container {
  /* 
  background: ;display: flex !important;
  justify-content: right !important;
  align-items: center;
  margin: 0px;
  */
}

.col-main {
  padding: 0px !important;
  margin: 0px !important;
  width: 100% !important;
}

.col-avatar {
  padding-left: 16px !important;
}

.nav-link {
  padding: 8px 0px;
}

.v-btn {
  font-size: 12px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.btn-new {
  font-size: 14px;
  color: white;
  width: 100px;
}

.td-height {
  min-height: 60px !important;
}


</style>