<template>
  <div id="ModalEnderecosCadastro">
      <v-toolbar-title v-bind:style="{ 'color':COR_SUBTITULO }" class="d-flex align-center justify-space-between body-1 font-primary ml-n2">
        <p class="font-primary font-weight-bold font-weight-medium body-1" v-bind:style="{ 'color':COR_SUBTITULO }">
          Endereço
        </p>
      </v-toolbar-title>
      <v-row>
        <v-col 
          cols="11"
          style="height: 30px"
          class="px-0">
          <v-text-field
            v-model="dados_modal_endereco.pessoa_endereco_cep"
            class="mt-n2"
            light
            background-color="#FFF"
            label="Cep"
            placeholder="xxxxx-xxx"
            filled
            outlined
            required
            dense
            :rules="cepRules"
            v-mask="MASK_CEP">
          </v-text-field>
        </v-col>
        <v-col 
          cols="1"
          class="px-0">
          <v-icon 
            class="btn-icon-plus mr-1 ml-2" 
            color="accent-4"
            @click="buscaEndereco(dados_modal_endereco.pessoa_endereco_cep)">mdi-magnify                    
          </v-icon>
        </v-col>
        <v-col 
          cols="12"
          class="px-0">
          <v-text-field
            v-model="dados_modal_endereco.pessoa_endereco_logradouro"
            class="mb-0"
            light
            background-color="#FFF"
            label="Endereço"
            placeholder="Endereço"
            filled
            outlined
            required
            :rules="enderecoRules"
            dense>
          </v-text-field>
          <v-text-field
            v-model="dados_modal_endereco.pessoa_endereco_numero"
            class="mt-n2"
            light
            background-color="#FFF"
            label="Número"
            placeholder="Número"
            filled
            :rules="numeroRules"
            outlined
            required
            dense>
          </v-text-field>
          <v-text-field
            v-model="dados_modal_endereco.pessoa_endereco_complemento"
            class="mt-n2"
            light
            maxlength="50"
            background-color="#FFF"
            label="Complemento"
            placeholder="Complemento"
            filled
            outlined
            dense>
          </v-text-field>
          <v-text-field
            v-model="dados_modal_endereco.pessoa_endereco_bairro"
            class="mt-n2"
            light
            background-color="#FFF"
            label="Bairro"
            placeholder="Bairro"
            filled
            outlined
            :rules="bairroRules"
            required
            dense>
          </v-text-field>
          <v-autocomplete
            v-model="dados_modal_endereco.cod_municipio"
            class=""
            :loading="loading"
            :search-input.sync="search"
            :items="arrayMunicipios"
            no-filter
            hide-selected
            auto-select-first
            clearable
            label="Município"
            item-text="nome" 
            item-value="codigo"
            filled 
            outlined 
            :rules="municipioRules"
            dense 
            background-color="#FFF" 
            required/>
          <v-select 
            label="Tipo"
            v-model="dados_modal_endereco.pessoa_endereco_tipo"
            class="mt-n2"
            filled
            outlined
            dense
            :rules="tipoRules"
            background-color="#FFF"
            :items="arrayEnderecoTipo"
            item-text="name">
          </v-select>
          <!-- <v-select 
            v-model="dados_modal_endereco.pessoa_endereco_corresp"
            label="Correspondência"
            placeholder="Correspondência"
            class="mt-n2"
            filled
            outlined
            dense
            background-color="#FFF"
            :items="arraySIM_NAO"
            item-text="name"
            required
            :readonly="correspondencia == 'S'">
          </v-select> -->
        </v-col>
      </v-row>
  </div>
</template>

<script>
import store_site from "../../../store/store_site"
import { API } from "../../../services/API";
import { COR_PRINCIPAL, COR_SUBTITULO, MASK_CEP, arrayEnderecoTipo, arraySIM_NAO } from "../../../services/constantes";
import store_Pessoa from "../store_Pessoa";
import axios from "axios";

export default {
  name: "PessoaEnderecosCadastro",

  props: [
    'dados_modal_endereco','dados_modal_municipio'],

  data() {
    return {
      store_site        : store_site,
      store_Pessoa      : store_Pessoa,
      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO,
      arrayEnderecoTipo : arrayEnderecoTipo,
      arrayMunicipios   : [],
      arraySIM_NAO      : arraySIM_NAO,
      MASK_CEP          : MASK_CEP,
      array_cep         : {},
      loading           : false,
      search            : null,
     
      dados_edicao      : {
        pessoa_endereco_cep: null,
        pessoa_endereco_logradouro: null,
        pessoa_endereco_bairro: null,
        pessoa_endereco_complemento: null,
        cod_municipio: null
      },
      
      municipios        : this.dados_modal_municipio,
      correspondencia   : this.dados_modal_endereco?.pessoa_endereco_corresp,

      // endereco_municipio: [
      // 'Franca',
      // 'Claraval',
      // ],

      // endereco_tipo: [
      // 'Residencial',
      // 'Casado (a)',
      // ],

      valid: true,
      form: false,

      enderecoRules: [
        //(value) => !!value || "O endereço é obrigatório",
        (value) =>
          (!value || value.length >= 3) ||
          "O endereço deve ter no mínimo 3 caracteres",
        (value) =>
          (!value || value.length <= 60) ||
          "O endereço deve ter menos do que 60 caracteres",
      ],
      numeroRules: [
        //(value) => !!value || !!this.dados_modal_endereco.pessoa_endereco_cep || "O número é obrigatório",
        //(value) => /^\d+$/.test(value) || 'O número não é válido',
        (value) =>
          (!value || value.length <= 10) ||
          "O número deve ter menos do que 10 caracteres",
      ],
      bairroRules: [
        //(value) => !!value || "O bairro é obrigatório",
        (value) =>
          (!value || value.length >= 3) ||
          "O bairro deve ter no mínimo 3 caracteres",
        (value) =>
          (!value || value.length <= 60) ||
          "O bairro deve ter menos do que 60 caracteres",
      ],
      cepRules: [
        //(value) => c || "O cep é obrigatório",
        //(value) => /^[0-9]{2}[0-9]{3}-[0-9]{3}$/.test(value) || "O cep não é válido",
        (value) =>
          (!value || value.length == 9) ||
            "O cep não é válido",
      ],
      municipioRules: [
        //(value) => 
          //(!value || this.dados_modal_endereco.pessoa_endereco_cep ||
          //  "O municipio é obrigatório"),
      ],
      tipoRules: [
        //(value) => !!value || this.dados_modal_endereco.pessoa_endereco_cep || "O tipo é obrigatório",
      ],
    };
  },

  watch: {
     search(query) {
      if (query && (!this.select || this.select.text !== query)) {
        this.querySearch(query);
      }
    },
  },

  methods: {
      busca_timeout(val) {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(async () => {
        const ls_Rel_Municipios = await API.get(`municipio`, val);
        if (Array.isArray(ls_Rel_Municipios.data.result)) {
          ls_Rel_Municipios.data.result.map(el => el.nome = `${el.nome} - ${el.uf}`)
          this.arrayMunicipios = ls_Rel_Municipios.data.result;
        }
      }, 600);
    },

    querySearch(query){
      let p_params = { params: { filtro: query } }
      this.loading = true
      this.busca_timeout(p_params);
      this.loading = false
    },  

    async buscaEndereco(val) {
      if(val && val.length == 9) {
        const ls_Rel_Cep = await axios.get(`https://viacep.com.br/ws/${ val }/json/`)
        if (!ls_Rel_Cep.data.erro) {
          this.array_cep = ls_Rel_Cep.data
          this.dados_modal_endereco.pessoa_endereco_logradouro = this.array_cep.logradouro
          this.dados_modal_endereco.pessoa_endereco_bairro = this.array_cep.bairro
          this.dados_modal_endereco.pessoa_endereco_logradouro = this.array_cep.logradouro
          
          const ls_Rel_Municipios = await API.get(`municipio`, {params: { cod_ibge: this.array_cep.ibge }});
          // console.log("ls_Rel_Municipios", ls_Rel_Municipios.data.result);
          if (ls_Rel_Municipios.data.result.length > 0) {
            let municipio_aux = [{
              codigo: ls_Rel_Municipios.data.result[0].codigo,
              nome: ls_Rel_Municipios.data.result[0].nome + ' - ' + ls_Rel_Municipios.data.result[0].uf
            }]

            this.arrayMunicipios =  municipio_aux
            // this.arrayMunicipios[0]

            this.dados_modal_endereco.cod_municipio = this.arrayMunicipios[0].codigo         
          }
        }
      }
    },
  },
     async mounted() {
      this.$nextTick(() => {
        this.arrayMunicipios = []
        this.arrayMunicipios = [this.dados_modal_endereco.pessoa_endereco_municipio]
        // console.log('array municipios', this.arrayMunicipios);
        // console.log("this.dados_modal_endereco.cod_municipio", this.dados_modal_endereco.cod_municipio);

      })
  },

};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.wrapper {
  height: 540px;
  overflow-y: auto;
}

.btn {
  width: 100px;
}

/* ---------- BARRA DE SCROLL ---------- */
.wrapper::-webkit-scrollbar {
  width: 5px;
}

.wrapper::-webkit-scrollbar-button {
  padding: 1px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
  height: 10px!important;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 
</style>