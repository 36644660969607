import Vue from "vue";
import { API, baseURL } from "../../services/API";
import store_site from "../../store/store_site";

function initialState() {
  return {
    dados             : [],
    orcamentos        : [],
    negociacoes       : [],
    cod_opor          : null,
    cod_fase          : null,
  }
}

var store_Negociacoes = new Vue({

  data () {
    return initialState();
  },

  methods: {

    resetData() { 
      Object.assign(this.$data, initialState());
    },

    ImagemCapaCaminhoCompleto(img_url) {
      var img = baseURL + img_url;
      return img
    },

    async filtra_negociacoes_abertas() {
      try {
        const response = await API.get("/site/cliente_negociacoes", {
          params: {
            tipo: "aguardando_resposta",
            page: 1,
          },
        });

        if (response) {
          // Resultado da Consulta -------------------
          if (response.data.result.data == "Não possui dados") {
            this.negociacoes = []            
          }
          else {
            this.negociacoes = response.data.result.data;
          }
        }

      } catch (err) {
        //console.log("[ERRO] : ", err);
      }

    },

  },

  created() {
    //console.log('CREATED - Store-Negociacoes ')
  },

  destroyed() {
    //console.log('DESTROYED - Store-Negociacoes ')
  }

})

export default store_Negociacoes
