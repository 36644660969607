<template>
  <div id="PessoaCadastro">
    <!-- TÍTULO DA PÁGINA -->
    <v-toolbar-title :style="{ 'color':COR_SUBTITULO }" class="d-flex align-center justify-space-between body-1 font-primary ml-2">
      <p class="font-primary font-weight-bold font-weight-medium body-1" v-bind:style="{ 'color':COR_SUBTITULO }">
        Dados Básicos
      </p>
    </v-toolbar-title>

    <!-------- FORM CADASTRO ----------------------------->
    <v-form
      v-model="valid"
      ref="form"
      class="mx-1 mt-3">
    
    <!-- TERCEIRA COLUNA / PF ------------------------>
      <v-row v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física'" class="col-terceira-pf">
        <v-col 
          :cols="isMobileProp ? 12 : 4"
          :sm="isMobileProp ? 12 : 4">
          <div class="d-flex justify-center mt-n10 mb-n2 pt-5">
            <v-avatar
              size="100px"
              v-ripple
              v-if="store_Pessoa.acao == 'C'"
              class="grey lighten-2"
            >
              <span
                v-if="!store_Pessoa.pessoa_edicao.pessoa_foto"
                color="#274ABB"
                class="headline"
                >
                {{ nomeIniciais(store_Pessoa.pessoa_edicao.pessoa_nome) }}
              </span>
              <img
                v-if="store_Pessoa.pessoa_edicao.pessoa_foto"
                :src="store_Pessoa.pessoa_edicao.pessoa_foto"
                alt="avatar"
              />
            </v-avatar>

            <v-avatar
              size="100px"
              v-ripple
              v-if="store_Pessoa.acao != 'C'"
              class="grey lighten-2"
            >
              <span
                v-if="!store_Pessoa.pessoa_edicao.pessoa_foto"
                v-on:click="dialog_crop = true">
                Clique p/ adicionar avatar
              </span>
              <img
                v-if="store_Pessoa.pessoa_edicao.pessoa_foto"
                v-on:click="dialog_crop = true"
                :src="store_Pessoa.pessoa_edicao.pessoa_foto"
                alt="avatar"
              />
            </v-avatar>
          </div>
        </v-col>

        <v-col
          v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física'"
          :cols="isMobileProp ? 12 : 8"
          class="pt-3 px-2">
          <v-text-field
            v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física'"
            v-model="store_Pessoa.pessoa_edicao.pessoa_nome"
            :readonly="store_Pessoa.acao == 'C'"
            class="input-nome mb-0 obrigatorio"
            loader-height="1"
            background-color="#FFF"
            label="Nome"
            placeholder="Nome..."
            light
            autofocus
            maxlength="60"
            filled
            outlined
            required
            dense
            :rules="nameRules">
          </v-text-field>
        </v-col>

        <v-col
          :cols="isMobileProp ? 12 : 4"
          class="pt-0 px-2">
          <v-text-field
            v-model="store_Pessoa.pessoa_edicao.pessoa_cpf"
            :readonly="store_Pessoa.acao == 'C'"
            v-mask="MASK_CPFCNPJ"
            class="mt-n5 mb-0 obrigatorio"
            loader-height="1"
            background-color="#FFF"
            label="CPF"
            placeholder="CPF"
            light
            filled
            outlined
            required
            dense
            max="14"
            :rules="cpfRules">
          </v-text-field>
        </v-col>

        <v-col 
          :cols="isMobileProp ? 12 : 8"
          :sm="isMobileProp ? 12 : 4"
          class=" pt-0 px-2 mt-n3">
          <DatePicker
            v-model="store_Pessoa.pessoa_edicao.pessoa_nasc_dt"
            label="Data do Nascimento"
            placeholder="dia/mês/ano"
            class="mr-0 mt-n2"
            outlined
            backgroundColor="#FFF"
            tabindex="0"
            dense
            :rules="dataRules"
            format="DD/MM/YYYY"
            :readonly="store_Pessoa.acao == 'C'"
          ></DatePicker>

        </v-col>
      </v-row>
    <!-- FIM TERCEIRA COLUNA / PF ------------------------>  

    <!-- QUARTA COLUNA / PF ------------------------>
      <v-row v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física'" class="col-quarta-pf mt-n6" >
        <v-col cols="4" class="d-none d-sm-flex"></v-col>

        <v-col 
          :cols="isMobileProp ? 12 : 12"
          :sm="isMobileProp ? 12 : 4"
          class="pt-0 px-2">
          <v-text-field
            v-model="store_Pessoa.pessoa_edicao.pessoa_rg"
            :readonly="store_Pessoa.acao == 'C'"
            class="input-rg mb-0"
            background-color="#FFF"
            label="RG"
            filled
            outlined
            dense
            :rules="rgRules">
          </v-text-field>
        </v-col>

        <v-col 
          :cols="isMobileProp ? 12 : 12"
          :sm="isMobileProp ? 12 : 4"
          class="pt-0 px-2">
          <v-text-field
            v-model="store_Pessoa.pessoa_edicao.pessoa_rg_orgao"
            :readonly="store_Pessoa.acao == 'C'"
            class="input-rg mb-0"
            light
            loader-height="1"
            background-color="#FFF"
            label="RG - Orgão Expedidor"
            placeholder="RG - Orgão Expedidor"
            filled
            outlined
            required
            dense
            :rules="orgaoRules">
          </v-text-field>
        </v-col>
      </v-row>
    <!-- FIM QUARTA COLUNA / PF ------------------------>

    <!-- QUINTA COLUNA / PF ------------------------>

      <v-row v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física'" class="mt-n2 mb-1">
        <v-col 
          :cols="isMobileProp ? 12 : 12"
          :sm="isMobileProp ? 12 : 4"
          :md="isMobileProp ? 12 : 2"
          :lg="isMobileProp ? 12 : 3"
          class="py-0 px-2">
          <v-select 
            v-model="store_Pessoa.pessoa_edicao.pessoa_sexo"
            :readonly="store_Pessoa.acao == 'C'"
            class="mt-n2 obrigatorio"
            label="Sexo"
            filled
            outlined
            dense
            background-color="map-deep-merge white"
            :items="arraySexo"
            item-text="name"
            :rules="sexoRules">
          </v-select>
        </v-col>

        <v-col 
          :cols="isMobileProp ? 12 : 12"
          :sm="isMobileProp ? 12 : 4"
          :lg="isMobileProp ? 12 : 3"
          class="py-0 px-2">
          <v-autocomplete
            v-model="store_Pessoa.pessoa_edicao.pessoa_nacionalidade"
            :readonly="store_Pessoa.acao == 'C'"
            label="Nacionalidade"
            class="mt-n2"
            filled
            outlined
            dense
            background-color="#FFF"
            :items="arrayPaises"
            item-text="nome">
          </v-autocomplete>
        </v-col>

        <v-col
            v-if="store_Pessoa.acao == 'C'"
            :cols="isMobileProp ? 12 : 12"
            :sm="isMobileProp ? 12 : 4"
            class="py-0 px-2">
            <v-text-field 
              v-model="textMunicipio" 
              class="mt-n2" 
              loader-height="1" 
              background-color="#FFF" 
              label="Naturalidade"
              placeholder="Naturalidade" 
              light 
              filled 
              outlined 
              required 
              :readonly="store_Pessoa.acao == 'C'"
              dense>
            </v-text-field>
          </v-col>
          
          <v-col 
            v-if="store_Pessoa.acao == 'E'"
            :cols="isMobileProp ? 12 : 12"
            :sm="isMobileProp ? 12 : 4"
            class="py-0 px-2" >
           
            <v-autocomplete
              v-model="store_Pessoa.pessoa_edicao.cod_municipio"
              :loading="loading"
              :search-input.sync="search"
              :items="arrayNaturalidade"
              no-filter
              hide-selected
              clearable
              class="mt-n2"
              label="Naturalidade"
              placeholder="Naturalidade"
              item-text="nome" 
              item-value="codigo"
              auto-select-first  
              filled 
              outlined 
              dense 
              background-color="map-deep-merge white" 
              :readonly="store_Pessoa.acao == 'C'"
            />
          </v-col>

        <v-col
          :cols="isMobileProp ? 12 : 12"
          :sm="isMobileProp ? 12 : 1"
          :md="isMobileProp ? 12 : 2"
          :lg="isMobileProp ? 12 : 2"
          class="py-0 px-2">
          <v-text-field
            v-model="store_Pessoa.pessoa_edicao.pessoa_creci"
            :readonly="store_Pessoa.acao == 'C'"
            class="mt-n2 mb-0"
            light
            loader-height="1"
            background-color="#FFF"
            label="Creci"
            placeholder="Creci"
            filled
            outlined
            dense
            :rules="[rules.max(15, store_Pessoa.pessoa_edicao.pessoa_creci)]">
          </v-text-field>
        </v-col>
      </v-row>
    <!-- QUINTA COLUNA / PF ------------------------>

    <!---- TELEFONES / PF ----------------------------------->
      <v-row v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física'" class="telefones mt-n3 mb-3 pt-0 px-0">
        <v-col 
          :cols="isMobileProp ? 12 : 12"
          :sm="isMobileProp ? 12 : 3"
          class="py-0 px-2">
          <v-text-field
            v-model="store_Pessoa.pessoa_edicao.fone_residencial"
            :readonly="store_Pessoa.acao == 'C'"
            v-mask="MASK_TELEFONE"
            class="mb-0"
            light
            loader-height="1"
            background-color="#FFF"
            label="Telefone Residencial"
            placeholder="(xx) xxxx-xxxx"
            filled
            outlined
            dense
            :rules="foneRules">
          </v-text-field>
        </v-col>

        <v-col 
          :cols="isMobileProp ? 12 : 12"
          :sm="isMobileProp ? 12 : 3"
          class="py-0 px-2">
          <v-text-field
            v-model="store_Pessoa.pessoa_edicao.fone_comercial1"
            :readonly="store_Pessoa.acao == 'C'"
            v-mask="MASK_TELEFONE"
            class="mb-0"
            light
            loader-height="1"
            background-color="#FFF"
            label="Telefone Comercial"
            placeholder="(xx) xxxx-xxxx"
            filled
            outlined
            dense
            :rules="foneRules">
          </v-text-field>
        </v-col>

        <v-col 
          :cols="isMobileProp ? 12 : 12"
          :sm="isMobileProp ? 12 : 3"
          class="py-0 px-2">
          <v-text-field
            v-model="store_Pessoa.pessoa_edicao.fone_celular1"
            :readonly="store_Pessoa.acao == 'C'"
            v-mask="MASK_TELEFONE"
            class="mb-0"
            light
            loader-height="1"
            background-color="#FFF"
            label="Celular 1"
            placeholder="(xx) xxxxx-xxxx"
            filled
            outlined
            dense
            :rules="celRules">
          </v-text-field>
        </v-col>  

        <v-col 
          :cols="isMobileProp ? 12 : 12"
          :sm="isMobileProp ? 12 : 3"
          class="py-0 px-2">
          <v-text-field
            v-model="store_Pessoa.pessoa_edicao.fone_celular2"
            :readonly="store_Pessoa.acao == 'C'"
            v-mask="MASK_TELEFONE"
            class="mb-0"
            light
            loader-height="1"
            background-color="#FFF"
            label="Celular 2"
            placeholder="(xx) xxxxx-xxxx"
            filled
            outlined
            dense
            :rules="celRules">
          </v-text-field>
        </v-col>
      </v-row>
    <!---- FIM TELEFONES / PF ----------------------------------->

    <!---- ÚLTIMA COLUNA / PF ----------------------------------->
      <v-row v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física'" class="mt-n3 mb-n4 px-0">
        <v-col 
          :cols="isMobileProp ? 12 : 12"
          :sm="isMobileProp ? 12 : 3"
          class="py-0 px-2">
          <v-text-field
            v-model="store_Pessoa.pessoa_edicao.pessoa_profissao"
            :readonly="store_Pessoa.acao == 'C'"
            class="mt-n2 mb-0"
            light
            loader-height="1"
            background-color="#FFF"
            label="Profissão"
            placeholder="Profissão"
            dense
            filled
            outlined
            :rules="[rules.max(60, store_Pessoa.pessoa_edicao.pessoa_profissao)]">
          </v-text-field>
        </v-col>

        <v-col 
          :cols="isMobileProp ? 12 : 12"
          :sm="isMobileProp ? 12 : 6"
          class="py-0 px-2">
          <v-text-field
            v-model="store_Pessoa.pessoa_edicao.pessoa_email"
            :readonly="store_Pessoa.acao == 'C'"
            class="mt-n2 mb-0"
            light
            loader-height="1"
            hint="Por exemplo: meunome@meuservidor.com.br"
            background-color="#FFF"
            label="Email"
            placeholder="Digite o seu email"
            filled
            outlined
            :rules="emailRules"
            dense>
          </v-text-field>
        </v-col>

        <v-col 
          :cols="isMobileProp ? 12 : 12"
          :sm="isMobileProp ? 12 : 3"
          class="py-0 px-2">
          <!-- <pre>{{store_Pessoa.pessoa_edicao.pessoa_estadocivil}}</pre> -->
          <v-select v-if="store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física'"
            v-model="store_Pessoa.pessoa_edicao.pessoa_estadocivil"
            :readonly="store_Pessoa.acao == 'C'"
            class="mt-n2 mb-10 obrigatorio"
            label="Estado Civil"
            filled
            outlined
            dense
            background-color="map-deep-merge white"
            :items="arrayEstadoCivil"
            item-text="name"
            :rules="estadocivilRules">
          </v-select>
        </v-col>  
      </v-row>
    <!---- ÚLTIMA COLUNA / PF ----------------------------------->

    <!----------- CONJUGE --------------------->
      <v-row v-if="(store_Pessoa.pessoa_edicao.pessoa_estadocivil === 'Casado(a)' || store_Pessoa.pessoa_edicao.pessoa_estadocivil === 'União Estável(a)')  && store_Pessoa.pessoa_edicao.pessoa_fisica_juridica === 'Física'" class="mb-12 px-0">
        <fieldset class="mt-n10 mb-6 d-flex flex-column mx-3 pt-4 px-3 pb-1 rounded" style="width: 100%; border: 1px solid #c4c4c4">
          <legend class="ml-1 mb-1 font-weight-medium body-2" v-bind:style="{ 'color':COR_SUBTITULO }"> Cônjuge </legend>

          <v-row class="px-2">
            <v-col cols="12" sm="12" class="py-0 px-2">
              <v-text-field
                v-model="conjuge.pessoa_nome"
                readonly
                class="input-conjuge mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Nome do Cônjuge M"
                placeholder="Nome do Cônjuge M"
                filled
                outlined
                require
                dense>
                <template v-slot:append-outer>
                  <v-icon
                    v-if="conjuge.cod_pessoa"
                    @click="store_Pessoa.dialogConjugeExcluir = true"
                    :disabled="store_Pessoa.acao == 'C'"
                    class="input-conjuge mb-0"
                    color="primary">
                    mdi-delete
                  </v-icon>
                  <v-icon
                    @click="store_Pessoa.dialogConjuge = true"
                    :disabled="store_Pessoa.acao == 'C'"
                    class="ml-2"
                    color="primary">
                    mdi-magnify-plus
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" class="py-0 px-2">
              <v-text-field
                v-model="conjuge.pessoa_cpf"
                v-mask="MASK_CPFCNPJ"
                class="input-conjuge mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="CPF do Cônjuge"
                placeholder="CPF do Cônjuge"
                filled
                outlined
                readonly
                dense>
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="12" class="py-0 px-2">
              <v-autocomplete
                v-model="conjuge.pessoa_nacionalidade"
                label="Nacionalidade"
                class="input-conjuge mb-0"
                filled
                outlined
                readonly
                dense
                background-color="#FFF"
                :items="arrayPaises"
                item-text="nome">
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" class="py-0 px-2">
              <DatePicker
                v-model="conjuge.pessoa_nasc_dt" 
                label="Nascimento do Cônjuge"
                placeholder="dia/mês/ano"
                class="input-conjuge mb-0"
                outlined
                backgroundColor="#FFF"
                tabindex="0"
                dense
                :rules="dataRules"
                format="DD/MM/YYYY"
                readonly>
              </DatePicker>
            </v-col>

          <v-col cols="12" sm="12" class="py-0 px-2">
              <v-text-field
                v-model="conjuge.pessoa_profissao"
                class="input-conjuge mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Profissão do Cônjuge"
                placeholder="Profissão do Cônjuge"
                filled
                outlined
                readonly
                dense>
              </v-text-field>
            </v-col>

            <!-- Regime de Bens -->
            <v-col cols="12" sm="12" class="py-0 px-2">
              <v-autocomplete
                v-model="store_Pessoa.pessoa_edicao.cod_regime"
                :readonly="store_Pessoa.acao == 'C'"
                class="input-conjuge mb-0"
                label="Regime de Bens"
                filled
                outlined
                dense
                clearable

                background-color="map-deep-merge white"
                :items="arrayRegimeBens"
                item-text="name"
                item-value="codigo">
              </v-autocomplete>
            </v-col>

            <v-col cols="12" sm="12" class="py-0 px-2">
              <DatePicker
                v-model="store_Pessoa.pessoa_edicao.pessoa_casamento_dt" 
                label="Data do Casamento"
                placeholder="dia/mês/ano"
                class="input-conjuge mb-0"
                outlined
                backgroundColor="#FFF"
                tabindex="0"
                dense
                required
                :rules="dataRules"
                format="DD/MM/YYYY"
                :readonly="store_Pessoa.acao == 'C'">
              </DatePicker>
            </v-col>
          </v-row>
        </fieldset>
      </v-row>
    <!----------- FIM CONJUGE --------------------->

      <v-card-text class="pt-n2 px-2" style="margin-top: -60px">
        <PessoaEnderecosCadastro 
          :dados_modal_endereco.sync="dados_modal_endereco"
          :dados_modal_municipio.sync="arrayMunicipios"
        />
      </v-card-text>
    </v-form>
  <!-- FIM FORM CADASTRO -->

    <!-- DIALOGO CONJUGE -------------------------------------------------------->
    <v-container v-if="store_Pessoa.dialogConjuge">
      <v-dialog
        v-model="store_Pessoa.dialogConjuge"
        scrollable
        max-width="500px"
        max-height="600px">
        <v-card>
          <!-- Cabecalho do Conjuge -->
          <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }">
            <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
              <div class="pa-0" style="width:100%">
                <p style="color:white; white-space: pre-line; font-size:16px !important;">
                  Busca de Pessoas
                </p>                      
              </div>
              <div class="pa-0 text-center" style="width:40px;">
                <v-btn icon dark v-on:click="store_Pessoa.dialogConjuge = false" class="mt-n2">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-card-title>
          
          <!-- Corpo do Conjuge -->
          <v-card-text class="pa-0" style="height: 500px;">
            <PessoaConsultaConjugeMobile
            class="mt-n2"
            :editar_="true"/>
          </v-card-text>

          <v-divider></v-divider>
          
          <!-- Rodape do Conjuge -->
          <v-card-actions class="d-flex justify-center pa-3" v-bind:style="{ 'background-color':COR_SECUNDARIA }">
            <v-btn
              @click="store_Pessoa.dialogConjuge = false; store_Pessoa.acao_abas = false"
              class="mr-4 btn text-none"
              color="primary"
              text>
              Cancelar
            </v-btn>
            <v-btn
              class="btn text-none"
              color="primary"
              :disabled="!store_Pessoa.pessoa_conjuge_selecionado"
              @click="selecionarConjuge(store_Pessoa.pessoa_conjuge_selecionado)">
              Selecionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

    <!-- FIM DIALOGO CONJUGE ---------------------------------------------------->

    <v-footer 
      color="transparent"
      elevation="0"
      class="footer justify-center mt-3 pt-3">
      <v-btn
        @click="cancelar()"
        id="btn_cancelar"
        ref="btn_cancelar"
        class="mr-4 caption font-weight-medium"
        color="primary"
        text>
        Cancelar
      </v-btn>

      <v-btn v-if="store_Pessoa.acao != 'C'"
        :disabled="!valid"
        :loading="loading"
        @click="validate()"
        class="btn caption font-weight-medium white--text"
        color="primary accent-4">
        Salvar
      </v-btn>
    </v-footer>

    <!-- Arquivo/Webcam Dialog ------------------------------------------ -->
    <v-dialog
      v-if="dialog_crop"
        :value="true"
        persistent
        style="width: 330"
        :max-width="330"
        :retain-focus="false">
      <v-card>
        <v-card-title>Foto</v-card-title>
        <v-card-text class="pt-7">
          <VueCropImage
            :img="store_Pessoa.pessoa_edicao.pessoa_foto"
            :imgsync.sync="store_Pessoa.pessoa_edicao.pessoa_foto"
            :dialog_crop.sync="dialog_crop"
            ref="VueCropImage"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- --------------------------------------------------- -->

    <!-- Dialogo Padrão de Exclusão das Tabelas Relationadas -->
    <v-dialog
      v-model="store_Pessoa.dialogConjugeExcluir"
      transition="dialog-bottom-transition"
      persistent
      max-width="410"
      class="pa-0">
      <v-divider></v-divider>

      <v-card elevation="0" class="">
      <v-card-title class="text-h6">
        Tem certeza que deseja LIMPAR os dados do Cônjuge?
      </v-card-title>
      <v-card-actions class="d-flex justify-center">
        <v-spacer></v-spacer>
          <v-btn
            class="mr-4"
            color="primary"
            text
            @click="store_Pessoa.dialogConjugeExcluir = false; store_Pessoa.acao_abas = false">
            Cancelar
          </v-btn>
          <v-btn
            class="btn white--text"
            color="primary accent-4"
            :loading="loading_excluir"
            @click="selecionarConjuge();">
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialogo Padrão de Exclusão das Tabelas Relacionadas -->
    <v-dialog
      v-model="dialog_excluir"
      transition="dialog-bottom-transition"
      persistent
      max-width="410"
      class="pa-0">
      <v-divider></v-divider>

        <v-card elevation="0" class="">
        <v-card-title class="text-h6">
          Tem certeza que deseja EXCLUIR este registro?
        </v-card-title>
        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
            <v-btn
              class="mr-4"
              color="primary"
              text
              @click="store_Pessoa.acao_abas = false; dialog_excluir = false; dialog_excluir_tipo = null; dialog_excluir_registro = null">
              Cancelar
            </v-btn>

            <v-btn
              class="btn white--text"
              color="primary accent-4"
              :loading="loading_excluir"
              @click="excluir_relacionados(dialog_excluir_tipo, dialog_excluir_registro)">
              Excluir
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import store_Pessoa from "./store_Pessoa";
import store_site from "../../store/store_site";
import store_ModalNegociacao from "../ModalNegociacao/store_ModalNegociacao";
import {
  formatDate,
  maskCpfCnpj,
  CpfCnpjIsValid,
  emailIsValid,
  telefoneIsValid,
} from "../../services/funcoes";
import { COR_PRINCIPAL, COR_SUBTITULO, COR_SECUNDARIA, MASK_CPFCNPJ, MASK_TELEFONE, arraySexo, arrayEstadoCivil, arrayPaises, arrayRegimeBens } from "../../services/constantes";
import { nomeIniciais } from "../../services/funcoes"
import { API } from "../../services/API"
import DatePicker from "../ComponentesGlobais/Date-Picker/date-picker.vue";
import VueCropImage from "../ComponentesGlobais/CropImage/VueCropImage.vue";
import PessoaConsultaConjugeMobile from "./PessoaConsultaConjugeMobile.vue"
import PessoaEnderecosCadastro from "./Enderecos/PessoaEnderecosCadastro.vue";

export default {
  name: "PessoaCadastroMobile",

  props: {
    isMobileProp: {
      type    : Boolean,
      default : false
    },
    itemDataTable: {
      type    : Object,
    }
  },

  components: {
    VueCropImage,
    DatePicker,
    PessoaConsultaConjugeMobile,
    PessoaEnderecosCadastro,
  },

  data() {
    return {
      store_Pessoa          : store_Pessoa,
      store_site            : store_site,
      store_ModalNegociacao : store_ModalNegociacao,

      maskCpfCnpj   : maskCpfCnpj,
      formatDate    : formatDate,
      nomeIniciais  : nomeIniciais,

      img                     : null,
      imgsync                 : null,
      dialog_crop             : false,
      dialog_webcam           : false,
      dialog_excluir_tipo     : false,
      dialog_excluir          : false,
      dialog_excluir_registro : false,
      loading_excluir         : false,

      MASK_CPFCNPJ      : MASK_CPFCNPJ,
      MASK_TELEFONE     : MASK_TELEFONE,
      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO,  
      COR_SECUNDARIA    : COR_SECUNDARIA,
      arraySexo         : arraySexo,
      arrayEstadoCivil  : arrayEstadoCivil,
      arrayPaises       : arrayPaises,
      arrayMunicipios   : null,
      arrayNaturalidade : null,
      arrayRegimeBens   : arrayRegimeBens,

      isMobile          : false,
      search            : null,

      pessoa_edicaoAux            : null,
      dados_modal_endereco        : {},
      dados_modal_contato         : {},
      dados_modal_bancario        : {cod_pessoa: null, cod_banco: null, acao: null},
      dados_modal_bancario_bancos : [],
      
    /* Campos */
      valid                 : true,
      loading               : false,

      conjuge               : { cod_pessoa       : null,
                                pessoa_nome      : null,
                                pessoa_cpf       : null,
                                pessoa_nasc_dt   : null,
                                pessoa_profissao : null
                              },

    /* Título Tabela Endereços */
      headers_enderecos: [
        { text: "Logradouro", value: "logradouro", class: 'title-text'},
        { text: "N", value: "N", class: 'title-text', },
        { text: "Compl", value: "Compl", class: 'title-text', },
        { text: "Bairro", value: "Bairro", class: 'title-text', },
        { text: "Cep", value: "Cep", class: 'title-text', },
        { text: "Cidade", value: "Cidade", class: 'title-text', },
        { text: ""},
      ],

    /* Títulos Tabela Contatos */
      headers_contatos: [
        { text: "Nome contato", value: "Nome contato", class: 'title-text' },
        { text: "Telefone 01", value: "Telefone 01", class: 'title-text' },
        { text: "Telefone 02", value: "Telefone 02", class: 'title-text' },
        { text: "Telefone 03", value: "Telefone 03", class: 'title-text' },
        { text: ""},        
      ],

    /* Títulos Tabela Dados Bancários */
      headers_dados_bancarios: [
        { text: "Banco", value: "Banco", class: 'title-text' },
        { text: "Agência", value: "Agência", class: 'title-text' },
        { text: "Conta", value: "Conta", class: 'title-text' },
        { text: "Titular", value: "Titular", class: 'title-text' },
        { text: "CPF/CNPJ", value: "CPF/CNPJ", class: 'title-text' },
        { text: ""},
      ],

    /* Tabela Dados Bancarios */
      dados_bancarios: [
        {banco: "Bradesco", agencia: "18009-23", conta: "00000000", titular: "Fernando Gracia", cpf_cnpj_titular: "000.000.000-00"},
      ],

    /* Títulos Tabela Sócios*/
      headers_socios: [
        { text: "Sócio", value: "Sócio", class: 'title-text' },
        { text: "Porcentagem %", value: "Porcentagem %", class: 'title-text' },
      ],

    /* Tabela Sócios */
        socios: [
        {socio: "João Feliciano", porcentagem: "20%"},
      ],

    /* Formulário Reprovação */
      //snack: false,
      //snackColor: "",
      //snackText: "",
      //form: false,
      //max25chars: (v) => v.length <= 25 || "Input too long!",

      rules: {
        min(min, v) { 
          return (v || '').length >= min || `Mínimo de ${min} caracteres`;
        },
        max(max, v) { 
          return (v || '').length <= max || `Máximo de ${max} caracteres`;
        },
        data(v) { 
          return (v || '').length >= 10 || `Mínimo de 10 caracteres`;
        },
      },
    
      nameRules: [
        (value) => !!value || "O nome é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O nome deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O nome deve ter menos do que 60 caracteres",
      ],
      razaoRules: [
        (value) => !!value || "A Razão Social é obrigatória",
        (value) =>
          (value && value.length >= 3) ||
          "A Razão Social deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "A Razão Social deve ter menos do que 60 caracteres",
      ],
      sexoRules: [
        (value) => !!value || "O Sexo é obrigatório",
      ],
      emailRules: [
        (value) => 
          ((!value || value.length <= 0) || (value && emailIsValid(value))) || "O e-mail não é válido",
        (value) =>
          (!value || value.length <= 100) || "O Email deve ter menos do que 100 caracteres",
      ],
      dataRules: [
        (value) => 
          ((!value || value.length <= 0) || (value && moment(value, 'DD/MM/YYYY', true).isValid())) || "A Data não é válida",
        (value) =>
          (!value || value.length <= 10) || "A Data deve ter menos do que 10 caracteres",
      ],
      rgRules: [
        (value) =>
          (!value || value.length <= 15) || "O RG deve ter menos do que 15 caracteres",
      ],
      orgaoRules: [
        (value) =>
          (!value || value.length <= 10) || "O Orgão Expedidor deve ter menos do que 10 caracteres",
      ],
      foneRules: [
        (value) => 
          ((!value || value.length <= 0) || (value && telefoneIsValid(value))) || "O telefone não é válido",
      ],
      celRules: [
        (value) => 
          ((!value || value.length <= 0) || (value && telefoneIsValid(value))) || "O celular não é válido",
      ],
      estadocivilRules: [
        (value) => !!value || "O Estado Civil é obrigatório",
      ],
      cpfRules: [
        (value) => !!value || "O CPF é obrigatório",
        (value) => (value && CpfCnpjIsValid(value)) || "O CPF não é válido",
        (value) =>
          (value && value.length <= 14) ||
          "O CPF deve ter 14 caracteres",
      ],
      cnpjRules: [
        (value) => !!value || "O CNPJ é obrigatório",
        (value) => (value && CpfCnpjIsValid(value)) || "O CNPJ não é válido",
        (value) =>
          (value && value.length <= 18) ||
          "O CNPJ deve ter 18 caracteres",
      ],
    };
  },

  created() {

    //if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    //    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4)))
    { 
      this.isMobile = true;
    }
    //if (this.isMobileProp)
    { 
      this.isMobile = true;
    }
  },

  computed: {
    textMunicipio() {
      return this.store_Pessoa.pessoa_edicao?.pessoa_municipio?.nome + " - " + this.store_Pessoa.pessoa_edicao?.pessoa_municipio?.uf
    },
  },
   watch: {

    search(query) {
      if (query && (!this.select || this.select.text !== query)) {
        this.querySearch(query);
      }
    },

  },
  
  async mounted() {
    console.log("itemDataTable", this.itemDataTable);
    if(!!this.store_Pessoa.pessoa_edicao.pessoa_conjuge){
      this.conjuge = this.store_Pessoa.pessoa_edicao.pessoa_conjuge;
    }

    // Filtra somente o endereco de correspondencia quando ja existe o endereço
    if (this.store_Pessoa.pessoa_edicao.pessoa_enderecos && this.store_Pessoa.pessoa_edicao.pessoa_enderecos.length > 0) {
      var aux = this.store_Pessoa.pessoa_edicao.pessoa_enderecos.filter((valor) =>  
        valor.pessoa_endereco_corresp == "S"
        )

        if(aux.length <= 0){
          this.dados_modal_endereco = this.store_Pessoa.pessoa_edicao.pessoa_enderecos[0];
        }
        else{
          this.dados_modal_endereco = aux[0];
        }
        this.arrayMunicipios = [this.dados_modal_endereco.pessoa_endereco_municipio]
    }

    //monta o array de naturalidade quando o cod_pessoa é maior que 0
    if(this.store_Pessoa.pessoa_edicao.cod_pessoa > 0){
      const ls_Rel = await API.get(`municipio`, {params : {cod_municipio : this.store_Pessoa.pessoa_edicao.cod_municipio}});
      ls_Rel.data.result.map(el => el.nome = `${el.nome} - ${el.uf}`)
        this.arrayNaturalidade = ls_Rel.data.result;
      // console.log("this.arrayNaturalidade", this.arrayNaturalidade);
    }

  },

  destroyed() {
    this.pessoa_edicaoAux = null;
    this.selecionarConjuge();
  },

  methods: {
    busca_timeout(val) {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(async () => {
        const ls_Rel_Municipios = await API.get(`municipio`, val);
        // console.log('array municipios', ls_Rel_Municipios);
        if (Array.isArray(ls_Rel_Municipios.data.result)) {
          ls_Rel_Municipios.data.result.map(el => el.nome = `${el.nome} - ${el.uf}`)
          this.arrayNaturalidade = ls_Rel_Municipios.data.result;
        }
      }, 600);
    },

    querySearch(query){
      let p_params = { params: { filtro: query } }
      this.loading = true
      this.busca_timeout(p_params);
      this.loading = false
    },  

    // clickBtnEditar(){
    //   this.store_Pessoa.acao = 'E'; 
    //   this.store_Pessoa.acao_abas = true; 
    //   this.pessoa_edicaoAux={...store_Pessoa.pessoa_edicao}; 
    //   this.arrayMunicipios = 
    //   [
    //     { 'codigo': store_Pessoa.pessoa_edicao.cod_municipio, 
    //       'nome': store_Pessoa.pessoa_edicao.pessoa_municipio.nome + ' - ' + store_Pessoa.pessoa_edicao.pessoa_municipio.uf
    //     }
    //   ]

    // },
   
    // save() {
    //   this.snack = true;
    //   this.snackColor = "success";
    //   this.snackText = "Data saved";
    // },
    // cancel() {
    //   this.snack = true;
    //   this.snackColor = "error";
    //   this.snackText = "Canceled";
    // },
    // open() {
    //   //store_Pessoa.dialogEnderecosCadastro = true;
    //   this.snack = true;
    //   this.snackColor = "info";
    //   this.snackText = "Dialog opened";
    // },
    // close() {
    //   this.snack = true;
    //   this.snackColor = "info";
    //   this.snackText = "Dialog closed";
    // },

    // maxValue(max) {
    //   return max;
    // },

    selecionarConjuge(elem) {
console.log('elem', elem);
      if (!elem) {
        this.conjuge = {  cod_pessoa       : null,
                          pessoa_nome      : null,
                          pessoa_cpf       : null,
                          pessoa_nasc_dt   : null,
                          pessoa_profissao : null
                        }
        this.store_Pessoa.pessoa_edicao.pessoa_casamento_dt = null;
        this.store_Pessoa.pessoa_edicao.cod_regime          = null;
        this.store_Pessoa.pessoa_edicao.pessoa_conjuge      = {...this.conjuge};
        this.store_Pessoa.pessoa_edicao.cod_conjuge         = null;
      } 
      else {
        this.conjuge = elem;
        this.store_Pessoa.pessoa_edicao.cod_conjuge = this.conjuge.cod_pessoa;
      }

      this.store_Pessoa.dialogConjugeExcluir  = false
      this.store_Pessoa.dialogConjuge         = false
    },

    startCameraStream() {
      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          console.log(
            "Browser doesn't support or there is some errors." + error
          );
        });
    },

    async getDevices() {
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        return false;
      }
      try {
        let allDevices = await navigator.mediaDevices.enumerateDevices();
        for (let mediaDevice of allDevices) {
          if (mediaDevice.kind === "videoinput") {
            let option = {};
            option.text = mediaDevice.label;
            option.value = mediaDevice.deviceId;
            this.options.push(option);
            this.devices.push(mediaDevice);
          }
        }
        return true;
      } catch (err) {
        throw err;
      }
    },

    // --------------------------------------------------------------------------
    abre() {
      if (!this.store_Pessoa.pessoa_edicao.pessoa_foto) {
        this.$refs.file_new.click();
      } else {
        this.$refs.file_update.click();
      }
    },

    async excluir_relacionados(p_Tipo, item) {
      var ls_Rel = null;
      item.acao = 'D';
      if (p_Tipo == 'ENDERECOS')
        ls_Rel = await API.post(`pessoa_endereco`, JSON.stringify(item));
      else if (p_Tipo == 'CONTATOS')
        ls_Rel = await API.post(`pessoa_contato`, JSON.stringify(item));
      else if (p_Tipo == 'CONTAS')
        ls_Rel = await API.post(`pessoa_conta`, JSON.stringify(item));
      if (ls_Rel.status == 200) {
        if (p_Tipo == 'ENDERECOS')
          this.store_Pessoa.pessoa_edicao.pessoa_enderecos = ls_Rel.data.result.data
        if (p_Tipo == 'CONTATOS')
          this.store_Pessoa.pessoa_edicao.pessoa_contatos = ls_Rel.data.result.data
        if (p_Tipo == 'CONTAS')
          this.store_Pessoa.pessoa_edicao.pessoa_contas = ls_Rel.data.result.data
        this.store_site.alert_cor       = "#00A000";
        this.store_site.alert_timeout   = 1500;
        this.store_site.alert_msg       = ls_Rel.data.result.result.trim();
        this.store_site.alert           = true;
      } else {
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = ls_Rel.data.errors.trim();
        this.store_site.alert           = true;
      }
      this.dialog_excluir = false;
    },
    cancelar() {
      this.store_Pessoa.dialog_cadastro_mobile = false;
      this.store_Pessoa.acao = 'C';
      this.store_Pessoa.pessoa_edicao = {};
      this.dados_modal_endereco = {};
      this.arrayMunicipios = [];
      this.store_Pessoa.pessoa_leads_edicao = false
    },
    async validate() {
      const lb_valido = this.$refs.form.validate();
      if (lb_valido) {
        this.loading = true;

        this.store_Pessoa.pessoa_edicao.pessoa_endereco_corresp = "S"

        var lo_JSON = { ...this.store_Pessoa.pessoa_edicao };
        var ls_Res;

        //Verifica se foi selecionado um conjuge
        if(this.conjuge){
          lo_JSON.cod_conjuge = this.conjuge.cod_pessoa;
        }

        if (this.store_Pessoa.acao == "E") {
          ls_Res = await this.store_Pessoa.PessoaPut(lo_JSON);
        }
        else{
          ls_Res = await this.store_Pessoa.PessoaPost(lo_JSON);
        }

        if ((ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') ||
            (ls_Res.status && ls_Res.status == 200)) {
          this.store_site.alert_cor       = "#00A000";
          this.store_site.alert_timeout   = 1500;
          this.store_site.alert_msg       = ls_Res.result.result.trim();
          this.store_site.alert           = true;

          // salvando os dados de negociação da pessoa em Qualificação/Leads/Propostas/Reservas
          // this.itemDataTable.cliente_nome =  this.store_Pessoa.pessoa_edicao.pessoa_nome
          // this.itemDataTable.cliente_cpf =  this.store_Pessoa.pessoa_edicao.pessoa_cpf
          // this.itemDataTable.cliente_celular =  this.store_Pessoa.pessoa_edicao.fone_celular1
          // this.itemDataTable.cliente_email =  this.store_Pessoa.pessoa_edicao.pessoa_email
          // this.itemDataTable.cliente_cnpj =  this.store_Pessoa.pessoa_edicao.pessoa_cnpj

          console.log('this.store_Pessoa.pessoa_edicao', this.store_Pessoa.pessoa_edicao);

        } else {
          this.store_site.alert_cor       = "#A00000";
          this.store_site.alert_timeout   = 20000;
          this.store_site.alert_msg       = ls_Res.errors.trim();
          this.store_site.alert           = true;
          this.loading = false;
          return
        }

        // Verificação se o usuario digitou endereço nos inputs
        if(this.dados_modal_endereco.pessoa_endereco_cep){
          var cod_pessoa_salva = ls_Res.result.data.cod_pessoa;
          
          //  Verificação se a pessoa ja possui endereço cadastrado ou não
          if(this.dados_modal_endereco.cod_pessoa_endereco){
            this.store_Pessoa.acao = 'E';
          }
          else{
            this.store_Pessoa.acao = 'I';
          }

          var lo_endereco = {...this.dados_modal_endereco, cod_pessoa: cod_pessoa_salva, acao: this.store_Pessoa.acao}
          var ls_Endereco = await this.store_Pessoa.PessoaEnderecoPost(lo_endereco)
          // console.log("ls_Endereco", ls_Endereco);
        }

        this.loading = false;
        this.store_Pessoa.acao = 'C';
        this.store_Pessoa.dialog_cadastro_mobile = false;
        this.store_Pessoa.preenche_search_pessoa_cadastrada = this.store_Pessoa.pessoa_edicao.pessoa_nome

        this.store_Pessoa.pessoa_edicao = {};

        this.store_ModalNegociacao.dialog_novo_lead = false
        this.$nextTick(() => {
          this.store_ModalNegociacao.dialog_novo_lead = true
        })
      }
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    /* Método que recebe arquivo de imagem */
    handleFile({ target }) {
      this.dialog_crop = true;
    },

    togglePlus () {
      this.plus = !this.plus
    },
  },
};
</script>

<style scoped>
#PessoaCadastro {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media(max-width: 599px) {
  #PessoaCadastro {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#PessoaCadastro::-webkit-scrollbar {
  width: 5px;
}

#PessoaCadastro::-webkit-scrollbar-button {
  padding: 1px;
}

#PessoaCadastro::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#PessoaCadastro::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #PessoaCadastro::-webkit-scrollbar {
    width: initial;
  }

  #PessoaCadastro::-webkit-scrollbar-button {
    padding: initial;
  }

  #PessoaCadastro::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #PessoaCadastro::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.nav-link {
  text-decoration: none;
}

.btn {
  width: 100px;
}

.btn-icon {
  font-size: 17px;
}

.btn-icon-plus {
  font-size: 20px;
}

.icon-action {
  font-size: 20px!important;
  color: #9E9E9E;
}

.my-custom-dialog {
  align-self: center;
  position: absolute;
  bottom: 0;
  right: 0;
  color: red;
}

.flex-column {
  flex-direction: column!important;
}

.input-rg {
  margin-top: -18px;
}

.input-inscricao {
  margin-top: -22px;
}

@media(max-width: 599px) {
  .input-rg {
    margin-top: -8px;
  }

  .input-inscricao {
    margin-top: -10px;
  }

  .col-terceira-pf div:nth-child(2), .col-terceira-pj div:nth-child(2) {
    margin-top: -16px;
  }

  .col-terceira-pj div:nth-child(1) {
    margin-bottom: 4px;
  }

  .col-terceira-pf div:nth-child(3), .col-terceira-pj div:nth-child(3) {
    margin-top: -12px;
  }

  .col-terceira-pj div:nth-child(3) {
    margin-bottom: -4px;
  }

  .col-quarta-pf div:nth-child(2), .col-quarta-pj div:nth-child(2) {
    margin-top: 13px;
    margin-bottom: -10px;
  }

  .col-quinta-pj div:nth-child(3) {
    margin-top: -12px;
  }

  .telefones div:not(.telefones div:first-child) {
    margin-top: -8px;
  }

  .input-nome {
    margin-bottom: -8px!important;
  }

  .input-razao {
    margin-bottom: -12px!important;
  }

  .input-date {
    margin-top: -12px;
  }

  .input-conjuge, .input-date-nasc, .input-date-cas {
    margin-top: -8px;
  }
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  padding: 2px;
  background: var(--COR_SECUNDARIA)!important;
  border-top-width: 1px!important;
  border-top-style: solid!important;
  border-top-color: var(--COR_SOMBRA)!important;
}

/* .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 10px;
  width: 200px !important;
} */

</style>
                
